import { NavLink } from 'react-router-dom';
import "./nav.css";
import { NavigationProps } from "../../types";
import { ExternalLinkIcon } from '../../assets';

const Nav: React.FC<NavigationProps> = ({ menuItems }) => {

  return (
    <nav className="nav">
      {menuItems.map((item) => (
        item.text === 'Shop' ? (
          <a
            href={item.link}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '.5rem',
              whiteSpace: 'nowrap',
            }}
            target="_blank"
            rel="noreferrer noopener"
            key={item.text}
          >
            {item.text} <ExternalLinkIcon />
          </a>
        ):(
          <NavLink to={item.link} className="nav-item" key={item.text}>
            {item.text}
          </NavLink>
        )
      ))}
    </nav>
  );
};

export default Nav;
