import { TEST_INDEXER, ENTITY_ACCOUNT, CONTRACT_ACCOUNT, TEST_NODE } from "../constants";
import { Item, Audio } from "../types";
import { AptosClient } from "aptos";

const client = new AptosClient(TEST_NODE);

export async function fetchGraphQL(
  operationsDoc: string,
  operationName: string,
  variables: Record<string, any>
) {
  try {
    const response = await fetch(TEST_INDEXER, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: operationsDoc,
        variables,
        operationName,
      }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const result = await response.json();
    if (result.errors) {
      throw new Error(`Error in GraphQL query: ${result.errors.map((e: any) => e.message).join(', ')}`);
    }

    return result;
  } catch (error: any) {
    console.error("Error in fetchGraphQL:", error.message);
    throw new Error("GraphQL fetch failed: " + error.message);
  }
}

export async function getItemDetails(itemAddress: string): Promise<Item> {
  try {
    const itemDetailsArray = await fetchItemDetails(itemAddress);
    const item = itemDetailsArray[0];

    return {
      name: item.current_token_data.token_name,
      collection: item.current_token_data.current_collection.collection_name,
      uri: item.current_token_data.token_uri,
    };

  } catch (error: any) {
    console.error(error);
    throw new Error("Failed to get item details: " + error.message);
  }
}

export async function fetchItemDetails(itemAddress: string): Promise<{[k: string]: any}[]> {
  const operations = `
    query ItemDetails {
      current_token_ownerships_v2(
        where: {storage_id: {_eq: "${itemAddress}"}}
        distinct_on: storage_id
      ) {
        current_token_data {
          token_name
          token_properties
          current_collection {
            collection_name
          }
          token_uri
        }
      }
    }
  `;
  try {
    const response = await fetchGraphQL(operations, "ItemDetails", {});
    const itemDetails = (response as any).data.current_token_ownerships_v2;
    return itemDetails;
  } catch (error: any) {
    console.error(error);
    throw new Error("Failed to fetch item details: " + error.message);
  }
}

export async function fetchOwnedAssets(accountAddress: string): Promise<{[k: string]: any}[]> {
  const operations = `
    query CurrentItems {
      current_token_ownerships_v2(
        where: {owner_address: {_eq: "${accountAddress}"}, current_token_data: {current_collection: {creator_address: {_eq: "${ENTITY_ACCOUNT}"}}}}
      ) {
        current_token_data {
          token_name
          current_collection {
            collection_name
          }
          token_properties
          token_uri
        }
        storage_id
      }
    }
  `;
  try {
    const response = await fetchGraphQL(operations, "CurrentItems", {});
    const currentItems = (response as any).data.current_token_ownerships_v2;
    return currentItems;
  } catch (error: any) {
    console.error(error);
    throw new Error("Failed to fetch owned assets: " + error.message);
  }
}

export async function checkClaimed(itemAddress: string): Promise<boolean | null> {
  // this check may need to be updated with a padded address
  if (!itemAddress.match(/^0x[a-fA-F0-9]{62,64}$/)) {
    return null;
  }

  const payload = {
    function: `${CONTRACT_ACCOUNT}::item_v2::claim_status`,
    type_arguments: [],
    arguments: [itemAddress],
  };
  try {
    const result = await client.view(payload);
    return Boolean(result[0]);
  } catch (error: any) {
    console.error(error);
    return null;
  }
}

const createAudioObject = (audio: any): Audio => {
  return {
    address: audio.storage_id,
    uri: audio.current_token_data.token_uri,
    name: audio.current_token_data.token_name.split(" - #")[0],
    artist: audio.current_token_data.token_properties.artist,
    duration: audio.current_token_data.token_properties.duration,
    published: audio.current_token_data.token_properties.published,
  };
};

export async function getOwnedMediaFromItem(item: Item): Promise<void> {
  try {
    if(!item.properties?.masterItem) {
      return
    }
    const mediaTokens = await fetchOwnedMediaFromItem(item.properties.masterItem);
    console.log(mediaTokens)

    for (const token of mediaTokens) {
      const tokenProperties = token.current_token_data.token_properties;
      switch (tokenProperties.type) {
        case 'master_audio':
          item.mediaFiles?.audio?.push(createAudioObject(token));
          break;
      }
    }

    return
  } catch (error: any) {
    console.error(error);
    return
  }
}

export async function fetchOwnedMediaFromItem(itemAddress: string): Promise<{[k: string]: any}[]> {
  const operations = `
    query CurrentTracks {
      current_token_ownerships_v2(
        where: {owner_address: {_eq: "${itemAddress}"}}
      ) {
        current_token_data {
          token_name
          token_properties
          token_uri
        }
        storage_id
      }
    }
  `;
  try {
    const response = await fetchGraphQL(operations, "CurrentTracks", {});
    const currentItems = (response as any).data.current_token_ownerships_v2;
    return currentItems;
  } catch (error: any) {
    console.error(error);
    throw new Error("Failed to fetch tracks from items: " + error.message);
  }
}
