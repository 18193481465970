import { CONTRACT_ACCOUNT, ENTITY_ACCOUNT, TEST_NODE, TEST_SERVER} from "../constants";
import { AptosClient, ApiError, FailedTransactionError, WaitForTransactionError } from "aptos";
import { queryResource, fetchGraphQL } from "./aptos-queries";
import { padAptosAddress } from "../utils";
import { MagicAptosWallet } from "@magic-ext/aptos";
import axios from "axios";
import { Token } from "../types";

const client = new AptosClient(TEST_NODE);

export interface CreateCreatorFormValues {
  username: string;
}

export interface CreateCollectionFormValues {
  name: string;
  description: string;
  uri: string;
  royaltyNumerator: string;
  royaltyDenominator: string;
}

export interface CreateAudioFormValues {
  collectionName: string;
  description: string;
  name: string;
  uri: string;
  royaltyNumerator: string;
  royaltyDenominator: string;
  royaltyAddress: string;
  artist: string;
  duration: string;
  published: string;
}

export interface CreateItemFormValues {
  collectionName: string;
  description: string;
  name: string;
  uri: string;
  royaltyNumerator: string;
  royaltyDenominator: string;
  royaltyAddress: string;
}

export interface CreateMasterFormValues {
  collectionName: string;
  itemAddress: string;
  mediaAddresses: string[];
}

export type Creator = {
  username: string;
  collections: Collection[];
}

export type Collection = {
  name: string;
  address: string;
}

export type ErrorState = {
  nameError?: string | null;
  descriptionError?: string | null;
  uriError?: string | null;
  numeratorError?: string | null;
  denominatorError?: string | null;
};

export const handleNewCreator = async (
  formValues: CreateCreatorFormValues,
  accountAddress: string,
  aptosWallet: MagicAptosWallet
) => {
  try {
    // ensure username is lowercased
    const formattedUsername = formValues.username.toLowerCase();

    // to server
    await addCreator(accountAddress, formattedUsername);
    // Creator struct on account
    await initCreator(formValues.username, aptosWallet);
  } catch (error: any) {
    throw new Error(`Failed to handle new creator: ${error.message}`);
  }
};

async function addCreator(accountAddress: string, username: string) {
  const url = `${TEST_SERVER}/creator`;
  const obj = {
    creatorAddress: accountAddress,
    username: username.toLowerCase()
  };

  try {
    const response = await axios.post(url, obj);

    if (response.status === 200) {
      console.log('Addition successful!');
      // Optionally return some data from the server response
      return response.data;
    } else {
      // Handle other successful status codes if needed
      console.log(response.data.message || 'An error occurred.');
      throw new Error(response.data.message || 'An error occurred during the request.');
    }
  } catch (error: any) {
    console.error(`Error in add creator:`, error);
    throw new Error(`Error submitting username for pre-registration: ${error.response?.data.message || error.message}`);
  }
};

async function initCreator(
  username: string,
  aptosWallet: MagicAptosWallet
) {
  // Create the payload for blockchain transaction
  const payload = {
    type: "entry_function_payload",
    function: `${CONTRACT_ACCOUNT}::creator_v2::init_creator`,
    type_arguments: [],
    arguments: [username, ENTITY_ACCOUNT],
  };

  // Submit the transaction
  try {
    const { hash } = await aptosWallet.signAndSubmitTransaction(payload);
    const transactionResult = await client.waitForTransactionWithResult(hash, { checkSuccess: true });
    console.log('Transaction successfully committed:', transactionResult);
  } catch (error: any) {
    if (error instanceof ApiError) {
      // Handle API error, which indicates a problem with the request
        console.error(`API error in transaction submission: ${error.message}`);
    } else if (error instanceof FailedTransactionError) {
        // Handle failed transaction, where the transaction was rejected or execution failed
        console.error(`Failed transaction: ${error.message}`);
        // You can extract more details from the error object if needed
    } else if (error instanceof WaitForTransactionError) {
        // Handle timeout error, where the transaction was not processed in the given timeframe
        console.error(`Transaction timed out: ${error.message}`);
    } else {
        // Handle other unexpected errors
        console.error(`Unexpected error in creator submission: ${error.message}`);
    }
    // Rethrow or handle the error as needed by your application logic
    throw new Error(`Failed to init Creator: ${error.message}`);
  }
}

export async function checkUsername(username: string): Promise<boolean> {
  const formattedUsername = username.toLowerCase();

  const payload = {
    function: `${CONTRACT_ACCOUNT}::creator_v2::creator_directory_contains`,
    type_arguments: [],
    arguments: [ENTITY_ACCOUNT, formattedUsername],
  };
  try {
    const result = await client.view(payload);
    return Boolean(result[0]);
  } catch (error: any) {
    console.error(error);
    return false;
  }
}

export async function checkForCreator(accountAddress: string): Promise<boolean> {
  const payload = {
    function: `${CONTRACT_ACCOUNT}::creator_v2::creator_exists`,
    type_arguments: [],
    arguments: [accountAddress],
  };
  try {
    const result = await client.view(payload);
    return Boolean(result[0]);
  } catch (error: any) {
    console.error(error);
    return false;
  }
}

export async function getCreatorInfo(accountAddress: string): Promise<Creator | null> {
  try {
    const resource = await queryResource(accountAddress, CONTRACT_ACCOUNT, "creator_v2", "Creator");
    if (!resource) return null;

    const username: string = (resource as any).data.username;
    const tableHandle = (resource as any).data.collections.inner.handle;
    const collections = await fetchCreatorCollections(padAptosAddress(tableHandle));
    const collectionList = buildCollectionList(collections);

    const creator: Creator = {
      username,
      collections: collectionList
    }

    return creator;
  } catch (error: any) {
    console.error(error)
    throw new Error("Failed to get creator info: " + error.message);
  }
}

export async function resubmitUsername(
  username: string,
  accountAddress: string,
  aptosWallet: MagicAptosWallet
): Promise<void> {
  // query creator directory on entity with username
  try {
    const resource = await queryResource(ENTITY_ACCOUNT, CONTRACT_ACCOUNT, "creator_v2", "CreatorDirectory");
    if (!resource) return;

    const tableHandle = (resource as any).data.creators.inner.handle;
    const creator = await fetchCreator(padAptosAddress(tableHandle), username);
    const { decoded_value } = creator[0];

    if (creator.length === 0 || decoded_value !== accountAddress) {
      return
    }

    // if they match, re-submit transaction to init_creator
    await initCreator(username, aptosWallet);
  } catch(error: any) {
    throw new Error(`Failed to resubmit username: ${error.message}`);
  }
}

async function fetchCreator(tableHandle: string, username: string): Promise<{[k: string]: any}[]> {
  const operations = `
    query MyQuery {
      current_table_items(
        where: {table_handle: {_eq: "${tableHandle}"}, decoded_key: {_eq: "${username}"}}
      ) {
        decoded_key
        decoded_value
      }
    }
  `;
  try{
    const response = await fetchGraphQL(operations, "MyQuery", {});
    const creator = (response as any).data.current_table_items;
    return creator;
  } catch(error: any) {
    throw new Error("Failed to fetch creator: " + error.message);
  }
}

async function fetchCreatorCollections(tableHandle: string): Promise<{[k: string]: any}[]> {
  const operations = `
    query CreatorCollections {
      current_table_items(
        where: {table_handle: {_eq: "${tableHandle}"}}
      ) {
        decoded_key
        decoded_value
      }
    }
  `;
  try{
    const response = await fetchGraphQL(operations, "CreatorCollections", {});
    const collections = (response as any).data.current_table_items;
    return collections;
  } catch(error: any) {
    throw new Error("Failed to fetch creator collections: " + error.message);
  }
}

function buildCollectionList(collectionList: {[k: string]: any}[]): Collection[] {
  return collectionList.map((collection) => {
    const { decoded_key, decoded_value } = collection;
    return { name: decoded_key, address: decoded_value.inner };
  });
}

export const handleNewCollection = async (
  formValues: CreateCollectionFormValues,
  accountAddress: string,
  aptosWallet: MagicAptosWallet
) => {
  // Create the payload for blockchain transaction
  const payload = {
    type: "entry_function_payload",
    function: `${CONTRACT_ACCOUNT}::collections_v2::init_collection`,
    type_arguments: [],
    arguments: [
      ENTITY_ACCOUNT,
      formValues.name,
      formValues.description,
      formValues.uri,
      formValues.royaltyNumerator,
      formValues.royaltyDenominator
    ],
  };

  try {
    // Submit the transaction
    const { hash } = await aptosWallet.signAndSubmitTransaction(payload);
    const transactionResult = await client.waitForTransactionWithResult(hash, { checkSuccess: true });
    console.log('Transaction successfully committed:', transactionResult);
  } catch (error: any) {
    if (error instanceof ApiError) {
      // Handle API error, which indicates a problem with the request
        console.error(`API error in transaction submission: ${error.message}`);
    } else if (error instanceof FailedTransactionError) {
        // Handle failed transaction, where the transaction was rejected or execution failed
        console.error(`Failed transaction: ${error.message}`);
        // You can extract more details from the error object if needed
    } else if (error instanceof WaitForTransactionError) {
        // Handle timeout error, where the transaction was not processed in the given timeframe
        console.error(`Transaction timed out: ${error.message}`);
    } else {
        // Handle other unexpected errors
        console.error(`Unexpected error in collection submission: ${error.message}`);
    }
    // Rethrow or handle the error as needed by your application logic
    throw new Error(`Failed to create new collection: ${error.message}`);
  }
};

export const handleNewAudio = async (
  formValues: CreateAudioFormValues,
  aptosWallet: MagicAptosWallet
) => {
  // Create the payload for blockchain transaction
  const payload = {
    type: "entry_function_payload",
    function: `${CONTRACT_ACCOUNT}::audio_v2::init_audio`,
    type_arguments: [],
    arguments: [
      ENTITY_ACCOUNT,
      formValues.collectionName,
      formValues.description,
      formValues.name,
      formValues.uri,
      formValues.royaltyNumerator,
      formValues.royaltyDenominator,
      formValues.royaltyAddress,
      formValues.artist,
      formValues.duration,
      formValues.published
    ],
  };

  try {
    // Submit the transaction
    const { hash } = await aptosWallet.signAndSubmitTransaction(payload);
    const transactionResult = await client.waitForTransactionWithResult(hash, { checkSuccess: true });
    console.log('Transaction successfully committed:', transactionResult);
  } catch (error: any) {
    if (error instanceof ApiError) {
      // Handle API error, which indicates a problem with the request
        console.error(`API error in transaction submission: ${error.message}`);
    } else if (error instanceof FailedTransactionError) {
        // Handle failed transaction, where the transaction was rejected or execution failed
        console.error(`Failed transaction: ${error.message}`);
        // You can extract more details from the error object if needed
    } else if (error instanceof WaitForTransactionError) {
        // Handle timeout error, where the transaction was not processed in the given timeframe
        console.error(`Transaction timed out: ${error.message}`);
    } else {
        // Handle other unexpected errors
        console.error(`Unexpected error in audio submission: ${error.message}`);
    }
    // Rethrow or handle the error as needed by your application logic
    throw new Error(`Failed to create new audio: ${error.message}`);
  }
};

export const handleNewItem = async (
  formValues: CreateItemFormValues,
  aptosWallet: MagicAptosWallet
) => {
  // Create the payload for blockchain transaction
  const payload = {
    type: "entry_function_payload",
    function: `${CONTRACT_ACCOUNT}::item_v2::init_item`,
    type_arguments: [],
    arguments: [
      ENTITY_ACCOUNT,
      formValues.collectionName,
      formValues.description,
      formValues.name,
      formValues.uri,
      formValues.royaltyNumerator,
      formValues.royaltyDenominator,
      formValues.royaltyAddress
    ],
  };

  try {
    // Submit the transaction
    const { hash } = await aptosWallet.signAndSubmitTransaction(payload);
    const transactionResult = await client.waitForTransactionWithResult(hash, { checkSuccess: true });
    console.log('Transaction successfully committed:', transactionResult);
  } catch (error: any) {
    if (error instanceof ApiError) {
      // Handle API error, which indicates a problem with the request
        console.error(`API error in transaction submission: ${error.message}`);
    } else if (error instanceof FailedTransactionError) {
        // Handle failed transaction, where the transaction was rejected or execution failed
        console.error(`Failed transaction: ${error.message}`);
        // You can extract more details from the error object if needed
    } else if (error instanceof WaitForTransactionError) {
        // Handle timeout error, where the transaction was not processed in the given timeframe
        console.error(`Transaction timed out: ${error.message}`);
    } else {
        // Handle other unexpected errors
        console.error(`Unexpected error in item submission: ${error.message}`);
    }
    // Rethrow or handle the error as needed by your application logic
    throw new Error(`Failed to create new item: ${error.message}`);
  }
};

export const handleNewMaster = async (
  formValues: CreateMasterFormValues,
  aptosWallet: MagicAptosWallet
) => {
  // Create the payload for blockchain transaction
  const payload = {
    type: "entry_function_payload",
    function: `${CONTRACT_ACCOUNT}::master_v2::init_master`,
    type_arguments: [],
    arguments: [
      ENTITY_ACCOUNT,
      formValues.collectionName,
      formValues.itemAddress,
      formValues.mediaAddresses
    ],
  };

  try {
    // Submit the transaction
    const { hash } = await aptosWallet.signAndSubmitTransaction(payload);
    const transactionResult = await client.waitForTransactionWithResult(hash, { checkSuccess: true });
    console.log('Transaction successfully committed:', transactionResult);
  } catch (error: any) {
    if (error instanceof ApiError) {
      // Handle API error, which indicates a problem with the request
        console.error(`API error in transaction submission: ${error.message}`);
    } else if (error instanceof FailedTransactionError) {
        // Handle failed transaction, where the transaction was rejected or execution failed
        console.error(`Failed transaction: ${error.message}`);
        // You can extract more details from the error object if needed
    } else if (error instanceof WaitForTransactionError) {
        // Handle timeout error, where the transaction was not processed in the given timeframe
        console.error(`Transaction timed out: ${error.message}`);
    } else {
        // Handle other unexpected errors
        console.error(`Unexpected error in master submission: ${error.message}`);
    }
    // Rethrow or handle the error as needed by your application logic
    throw new Error(`Failed to create new master: ${error.message}`);
  }
};

export async function getCollectionTokens(collectionAddress: string): Promise<Token[]> {
  try {
    const collections = await fetchCollectionTokens(padAptosAddress(collectionAddress));
    const tokenList = buildTokenList(collections);

    return tokenList;
  } catch (error: any) {
    console.error(error)
    throw new Error("Failed to get creator info: " + error.message);
  }
}

export async function fetchCollectionTokens(collectionAddress: string): Promise<any[]> {
  const operations = `
    query CollectionTokens {
      current_token_datas_v2(
        where: {current_collection: {creator_address: {_eq: "${ENTITY_ACCOUNT}"}, collection_id: {_eq: "${collectionAddress}"}}}
      ) {
        current_token_ownerships(distinct_on: storage_id) {
          current_token_data {
            token_name
            token_properties
          }
          storage_id
        }
      }
    }
  `;
  try {
    const response = await fetchGraphQL(operations, "CollectionTokens", {});
    console.log(response)
    const currentTokens = response.data.current_token_datas_v2;
    return currentTokens;
  } catch (error: any) {
    console.error(error);
    throw new Error("Failed to fetch collection tokens: " + error.message);
  }
}


function buildTokenList(tokenList: any[]): Token[] {
  const mappedList = tokenList.flatMap((tokenOwnership) =>
    tokenOwnership.current_token_ownerships.map((ownership: any) => ({
      name: ownership.current_token_data.token_name,
      properties: ownership.current_token_data.token_properties,
      address: ownership.storage_id,
    }))
  );

  const sortedList = mappedList.sort((a, b) => a.properties.type.localeCompare(b.properties.type));

  return sortedList;
}

/**
 * Checks the existence of media files for a batch of token addresses.
 * @param tokenAddresses Array of token addresses to check.
 * @returns Promise that resolves to an array of objects with tokenAddress and exists properties.
 */
export async function checkMedia(tokenAddresses: string[]): Promise<{ tokenAddress: string; exists: boolean }[]> {
  const url = `${TEST_SERVER}/media/exist`;

  try {
    const response = await axios.post(url, { tokenAddresses });
    return response.data;
  } catch (error) {
    console.error('Error checking media existence:', error);
    throw new Error('Failed to check media existence');
  }
}
