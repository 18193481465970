import { fetchOwnedAssets, getOwnedMediaFromItem } from "./common";
import { Item } from "../types";

export async function getLibraryData(accountAddress: string): Promise<Item[]> {
  try {
    const ownedAssets = await fetchOwnedAssets(accountAddress);
    const items = [];
    // const masterItemCount = new Map();
    const uniqueMasterItems = new Set();
    const fetchMediaTasks = [];

    for (const asset of ownedAssets) {
      if (asset.current_token_data.token_properties.type === 'item') {
        const edition = parseInt(asset.current_token_data.token_properties.edition, 10);
        let collection = asset.current_token_data.current_collection.collection_name;

        if (edition !== 0) {
          const collectionParts = collection.split("/");
          collection = collectionParts[1];
        }

        const item: Item = {
          address: asset.storage_id,
          uri: asset.current_token_data.token_uri,
          name: asset.current_token_data.token_name.split(" - #")[0],
          properties: {
            masterItem: asset.current_token_data.token_properties.master_item
          },
          collection,
          edition,
          mediaFiles: {
            audio: []
          }
        };

        items.push(item);

        if (item.properties?.masterItem) {
          const masterItem = item.properties.masterItem;
          // If this is the first occurrence of the masterItem, initialize fetching media.
          if (!uniqueMasterItems.has(masterItem)) {
            uniqueMasterItems.add(masterItem);
            fetchMediaTasks.push(getOwnedMediaFromItem(item));
          }
          // Update the count for each occurrence of masterItem.
          // masterItemCount.set(masterItem, (masterItemCount.get(masterItem) || 0) + 1);
        }
      }
    }

    await Promise.all(fetchMediaTasks);
    console.log(items)

    return items
  } catch (error: any) {
    throw new Error("Failed to get library data: " + error.message);
  }
}
