export interface ErrorState {
  nameError?: string | null;
  descriptionError?: string | null;
  uriError?: string | null;
  numeratorError?: string | null;
  denominatorError?: string | null;
}

export const validateUserName = (name: string, max: number): string | null => {
  const regex = new RegExp(`^[A-Za-z0-9_]{3,${max}}$`);
  if (!regex.test(name)) {
      return "Invalid name. Please follow the guidelines.";
  }
  return null;
};

export const validateName = (name: string, max: number): string | null => {
  const regex = new RegExp(`^[\x20-\x7E]{3,${max}}$`);
  if (!regex.test(name)) {
      return `Name must be between 3 and ${max} characters and include only ASCII characters.`;
  }
  return null;
};

export const validateDesc = (desc: string): string | null => {
  // This regex matches any printable ASCII character, including spaces and symbols.
  const regex = /^[\x20-\x7E]{3,2048}$/;
  if (!regex.test(desc)) {
    return "Description must be between 3 and 2048 characters and include only ASCII characters.";
  }
  return null;
};

export const validateUri = (uri: string): string | null => {
  // This regex checks for a basic structure of a URI
  // Note: This is a simple validation and might not cover all edge cases.
  const regex = /^(https?:\/\/)[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
  console.log(uri.length)
  if (!regex.test(uri)) {
    return "Invalid URL format.";
  }
  if (uri.length > 512) {
    return "URL must be under 512 characters.";
  }
  return null;
};

export const validateRoyaltyNumerator = (numerator: string, denominator: string): string | null => {
  const num = parseInt(numerator, 10);
  const den = parseInt(denominator, 10);
  if (isNaN(num) || num < 0 || num > 100 || num >= den) {
    return "Invalid royalty numerator. It must be a number from 0-100 and less than the denominator.";
  }
  return null;
};

export const validateRoyaltyDenominator = (denominator: string, numerator: string): string | null => {
  const den = parseInt(denominator, 10);
  const num = parseInt(numerator, 10);
  if (isNaN(den) || den <= 0 || den > 100 || den <= num) {
    return "Invalid royalty denominator. It must be a number from 1-100 and greater than the numerator.";
  }
  return null;
};
