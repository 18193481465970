import { AptosClient } from "aptos";
import { TEST_INDEXER, TEST_NODE } from "../constants";

const client = new AptosClient(TEST_NODE);

export async function queryResource(
  accountAddress: string,
  contractAddress: string,
  moduleName: string,
  structName: string
): Promise<any> {
  try {
    const resourceInfo = await client.getAccountResource(
      accountAddress,
      `${contractAddress}::${moduleName}::${structName}`
    )
    return resourceInfo;
  } catch(error: any) {
    console.error(`No resource found for
     ${contractAddress}::${moduleName}::${structName} under ${accountAddress}`);
    return null;
  }
}

export function fetchGraphQL(
  operationsDoc: string,
  operationName: string,
  variables: Record<string, any>
) {
  return fetch( TEST_INDEXER, {
    method: 'POST',
    body: JSON.stringify({
      query: operationsDoc,
      variables,
      operationName,
    }),
  }).then(result => result.json());
}
