import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Token } from '../types';
import { useAuth, useConfirmationDialog } from "../context";
import { ToastContainer, toast } from 'react-toastify';
import { ConfirmationDialog } from '../components';
import { uploadFiles } from '../api/files';
import styles from "./forms.module.css";

interface UploadMediaFormProps {
  token: Token;
  replace: boolean | undefined;
  onClose: () => void;
  onUpdate: (mediaAddress: string) => void;
  showToastSuccess: (message: string) => void;
  showToastError: (message: string) => void;
}

const FileUploadComponent: React.FC<UploadMediaFormProps> = ({
  onClose,
  replace,
  token,
  onUpdate,
  showToastError,
  showToastSuccess
}) => {
  const isSessionValid = useAuth();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    isConfirmationOpen,
    confirmationMessage,
    openConfirmationDialog,
    handleConfirm,
    closeConfirmationDialog
  } = useConfirmationDialog();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file && file.type === "audio/mpeg") {
      setSelectedFile(file);
    } else {
      toast.error("Please upload an MP3 File");
    }
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: { 'audio/mpeg': ['.mp3'] },
  });

  const handleUpload = async () => {
    if (!isSessionValid) {
      toast.error("Your session is not valid.");
      return;
    }

    if (!selectedFile) {
      toast.error("No file selected.");
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('mediaAddress', token.address ?? "");

    const message = replace ? `Overwrite stored media?` : `Upload media?`;
    setIsSubmitting(true);
    openConfirmationDialog(async () => {
      try {
        const { data } = await toast.promise(
          uploadFiles(formData),
          {
            pending: 'Uploading media...',
            error: 'Error: Could not store media.'
          }
        );
        if (data && data.uploaded) {
          onUpdate(token.address ?? "");
          showToastSuccess('Media successfully stored!');
        } else {
          showToastError('Media not uploaded. Try again later.');
        }
        onClose();
      } catch (error) {
        console.error('Error uploading the file:', error);
        showToastError('An unexpected error occurred.');
      } finally {
        setIsSubmitting(false);
      }
    }, message);
  };


  return (
    <div className={styles.collectionForm}>
      <h3>{replace ? 'Overwrite' : 'Upload'} Media</h3>
      <label style={{ display: 'inline' }}>Media Title:</label>
      <div style={{ display: 'inline', marginInlineStart: '.25rem' }}>{token.name}</div>

      <label style={{ display: 'block' }}>File: {selectedFile?.name}</label>
      <div {...getRootProps()}
        style={{
          border: '2px dashed black',
          textAlign: 'center',
          borderRadius: '.5rem',
          maxWidth: '60ch',
          marginInlineStart: 'auto',
          marginInlineEnd: 'auto',
          marginBlockStart: '.5rem',
          paddingBlock: '2rem',
          paddingInline: '2rem'}}>

        <input {...getInputProps()} />
        {isDragActive ?
          <p>Drop the file here ...</p> :
          <p>Drag 'n' drop a file here,<br/> or click to add file</p>
        }
      </div>
      <div className={styles.formBtns}>
        <button onClick={onClose}>Cancel</button>
        <button onClick={handleUpload} disabled={isSubmitting}>Upload Media</button>
      </div>
      <ConfirmationDialog
        message={confirmationMessage}
        isOpen={isConfirmationOpen}
        onConfirm={handleConfirm}
        onCancel={closeConfirmationDialog}
      />
      <ToastContainer />
    </div>
  );
};

export default FileUploadComponent;
