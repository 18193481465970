import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Root } from './layouts'
import { checkClaimed } from "./api/common";
import { ScanRedirect } from "./components";
import { Error, Library, Claim, Account, Create, M1rror } from './views'
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      errorElement: <Error />,
      children: [
        {
          path: 'library',
          element: <Library />,
        },
        {
          path: 'claim/:itemAddress',
          element: <Claim />,
          loader: async ({params}) => {
            return await checkClaimed(`${params.itemAddress}`);
          }
        },
        {
          path: 'm1rror/:itemAddress',
          element: <M1rror />,
          loader: async ({params}) => {
            return await checkClaimed(`${params.itemAddress}`);
          },
        },
        {
          path: 'account',
          element: <Account />,
        },
        {
          path: 'create',
          element: <Create />,
        },
        {
          path: ':itemAddress',
          element: <ScanRedirect />,
          loader: async ({params}) => {
            const claimedStatus = await checkClaimed(`${params.itemAddress}`);
            if (claimedStatus) {
              throw new Response(null, {
                status: 302,
                headers: {
                  Location: `/m1rror/${params.itemAddress}`,
                },
              });
            } else {
              throw new Response(null, {
                status: 302,
                headers: {
                  Location: `/claim/${params.itemAddress}`,
                },
              });
            }
          }
        },
      ],
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
