import { TEST_SERVER } from "../constants";
import axios from "axios";
import { VerificationParams } from "../types";

export async function codeRequest(itemAddress: string) {
  const url = `${TEST_SERVER}/claim/${itemAddress}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error: any) {
    console.error(`Error in claimRequest for itemAddress ${itemAddress}:`, error);
    throw new Error(`Error in claim request for itemAddress ${itemAddress}: ${error.message}`);
  }
};

export async function claimAttempt(obj: VerificationParams) {
  const url = `${TEST_SERVER}/verify`;

  try {
    const response = await axios.post(url, obj);

    if (response.status === 200) {
        console.log('Claim successful, check your wallet!');
    } else {
        console.log(response.data.message || 'An error occurred.');
    }
  } catch (error: any) {
    console.error(`Error in claimAttempt for itemAddress ${obj.itemAddress}:`, error);
    throw new Error(`Error in claim attempt for itemAddress ${obj.itemAddress}: ${error.message}`);
  }
}
