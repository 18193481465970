import { useState, useEffect } from "react";
import { useAuth } from "../../context";
import { getLibraryData } from "../../api/library";
import { Items, AudioComp } from "./index";
import { LoginModal } from "../../components";
import { Item, Audio } from "../../types";
import styles from './library.module.css';

const Library: React.FC = () => {
  const { isLoggedIn, accountInfo, isSessionValid } = useAuth();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeComponent, setActiveComponent] = useState('audio');
  const [libraryData, setLibraryData] = useState<{
    items: Item[];
    audio: Audio[];
  }>({ items: [], audio: [] });

  useEffect(() => {
    const fetchData = async () => {
      if (isSessionValid && accountInfo) {
        setLoading(true);
        try {
          const items = await getLibraryData(accountInfo.address);
          if (items) {
            const allAudioTokens: Audio[] = items.reduce((acc: Audio[], item: Item) => {
              if (item.mediaFiles?.audio && item.mediaFiles.audio.length > 0) {
                // Here, you might want to add any additional item-specific data to the audio object if needed
                acc.push(...item.mediaFiles.audio.map(audio => ({ ...audio, itemAddress: item.address })));
              }
              return acc;
            }, []);
            setLibraryData({items, audio: allAudioTokens});
          }
        } catch (error: any) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [accountInfo, isSessionValid]);

  useEffect(() => {
    const originalBackgroundColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = 'white';

    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);

  return (
    <div className={styles.library}>
      {!isLoggedIn ? (
        <section className={styles.loginPrompt}>
          <span>Please log in to view your item library.</span><br /><br />
          <button
            onClick={() => setIsLoginModalOpen(true)}
          >Login / Sign Up</button>
          {isLoginModalOpen && <LoginModal onClose={() => setIsLoginModalOpen(false)} />}
        </section>
      ) : (
        <>
          <div className={styles.nav}>
            <button
              onClick={() => setActiveComponent('audio')}
              className={activeComponent === 'audio' ? styles.active : ''}
            >Audio
            </button>
            |
            <button
              onClick={() => setActiveComponent('items')}
              className={activeComponent === 'items' ? styles.active : ''}
            >Items
            </button>
          </div>
          {activeComponent === 'audio' &&
            <AudioComp
              audio={libraryData.audio}
              loading={loading}
            />
          }
          {activeComponent === 'items' &&
            <Items
              items={libraryData.items}
              loading={loading}
            />
          }
        </>
      )}
    </div>
  );
}

export default Library;
