import React, { useState } from 'react';
import styles from "./forms.module.css";
import { useAuth, useConfirmationDialog } from "../context";
import { CreateCreatorFormValues, checkUsername, handleNewCreator } from '../api/create';
import { ToastContainer, toast } from 'react-toastify';
import { ConfirmationDialog } from '../components';
import { validateUserName } from './validation';

interface NewCreatorFormProps {
  onClose: () => void;
  onUpdate: () => Promise<void>;
  showToastSuccess: (message: string) => void;
  showToastError: (message: string) => void;
}

const NewCreatorForm: React.FC<NewCreatorFormProps> = ({
  onClose,
  onUpdate,
  showToastSuccess,
  showToastError
}) => {
  const { accountInfo, aptosWallet, isSessionValid } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string|null>('');
  const [formValues, setFormValues] = useState<CreateCreatorFormValues>({
    username: '',
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isSessionValid || !accountInfo || !aptosWallet) {
      toast.error("Your session is not valid.");
      return;
    }
    if (validateUserName(formValues.username, 30)) {
      toast.error("The username is invalid.");
      return;
    }
    const usernameAvailable = await checkUsername(formValues.username);
    if(usernameAvailable) {
      toast.error("The username is taken.");
      return;
    }

    const message = `Approve this username?`;
    setIsSubmitting(true);
    openConfirmationDialog(async () => {
      try {
        await toast.promise(
          handleNewCreator(formValues, accountInfo.address, aptosWallet),
          {
            pending: 'Adding username...',
            error: 'Error: Could not add username.'
          }
        );
        showToastSuccess('Username successfully added!');
        await onUpdate();
        onClose();
      } catch (error: any) {
        console.error("Error during creator creation:", error);
        let errorMessage = 'An unexpected error occurred.';
        if (typeof error === 'string') {
          // Direct string error
          errorMessage = parseErrorMessage(error);
        } else if (error.message) {
          // Error object with message
          errorMessage = parseErrorMessage(error.message);
        } else if (error.response && error.response.data && typeof error.response.data === 'string') {
          // HTTP response error
          errorMessage = parseErrorMessage(error.response.data);
        }

        showToastError(errorMessage);
      } finally {
        setIsSubmitting(false);
      }
    }, message);
  };

  function parseErrorMessage(errorMsg: string) {
  if (errorMsg.includes('EWHITELISTER_DN_EXIST')) {
    return 'Your account is not on the whitelist.';
  }
    // Add other error messages parsing here as needed
    return errorMsg; // Return the original message by default
  }

  const {
    isConfirmationOpen,
    confirmationMessage,
    openConfirmationDialog,
    handleConfirm,
    closeConfirmationDialog
  } = useConfirmationDialog();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let error: string | null = null;

    switch (name) {
      case "username":
        error = validateUserName(value, 30);
        break;
      default:
        break;
    }
    if(error){
      setError(error);
    } else {
      setError("");
    }
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.collectionForm}>
        <div>
          <h3>Add New Username</h3>
          <div className={`switcher ${styles.collectionFormBody}`}>
            <section>
              <label style={{display: 'block', textDecoration: "underline"}}>Check Availability:</label>
              <input
                type="text"
                name="username"
                value={formValues.username}
                onChange={handleChange}
              />
              {error && <div style={{ color: 'red', fontSize: 'small'}}>{error}</div>}
              <ul style={{fontSize: 'small'}}>
                <li>Only alphanumeric characters (letters a-z, numbers 0-9) with the exception of underscores</li>
                <li>No symbols, dashes (other than underscores), or spaces</li>
                <li>3 - 30 characters</li>
              </ul>
            </section>
            <section style={{border: '1px black solid', padding: '1rem'}}>
              <label style={{display: 'block', textDecoration: "underline"}}>Rules:</label>
              <ol>
                <li>One username allowed per account address</li>
                <li>Username cannot be changed after confirmed submission</li>
                <li>Usernames are all lowercase</li>
              </ol>
            </section>
          </div>
        </div>
        <div className={styles.formBtns}>
          <button onClick={onClose}>Cancel</button>
          <button type="submit" disabled={isSubmitting}>Submit</button>
        </div>
      </form>
      <ConfirmationDialog
        message={confirmationMessage}
        isOpen={isConfirmationOpen}
        onConfirm={handleConfirm}
        onCancel={closeConfirmationDialog}
      />
      <ToastContainer />
    </>

  );
};

export default NewCreatorForm;
