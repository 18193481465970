import "./footer.css";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
      <footer>
        <div className='h-footer'>
          <div className="footer-l">
            <p>{'\u00A9'}2023 M1rror, LLC</p>
            <p>{'\u2022'}</p>
            <Link to="#"
              target='_blank' rel="noreferrer noopenner"><p>Terms</p></Link>
            <p>{'\u2022'}</p>
            <Link to="#"
              target='_blank' rel="noreferrer noopenner"><p>Privacy</p></Link>
          </div>
          <div className="footer-r">
            <p>English (US)</p>
            <p>{'\u2022'}</p>
            <Link to="#"
              target='_blank' rel="noreferrer noopenner"><p>Support & Resources</p></Link>
          </div>
        </div>
        <div className="v-footer">
          <ul>
            <li><Link to="#"
              target='_blank' rel="noreferrer noopenner"><p>Terms</p></Link></li>
            <li><Link to="#"
              target='_blank' rel="noreferrer noopenner"><p>Privacy</p></Link></li>
            <li><Link to="#"
              target='_blank' rel="noreferrer noopenner"><p>Support & Resources</p></Link></li>
            <li><p>English (US)</p></li>
            <li>{'\u00A9'}2023 M1rror, LLC</li>
          </ul>
        </div>
      </footer>
  );
};

export default Footer;
