import axios from 'axios';
import { TEST_SERVER, TEST_MUSIC_PLAYER } from '../constants';

export const uploadFiles = async (formData: FormData) => {
  const url = `${TEST_SERVER}/upload`;

  try {
    const response = await axios.post(url, formData);
    console.log('Upload response:', response.data); // Logging response for debugging
    return response.data;
  } catch (error) {
    console.error('Error uploading media file:', error);
    throw error;
  }
};

export const getStreamUrl = async (mediaAddress: string, itemAddress: string, ownerAddress: string): Promise<string> => {
  try {
    const response = await axios.post(`${TEST_MUSIC_PLAYER}/stream`, {
      mediaAddress,
      itemAddress,
      ownerAddress
    });

    if (response.status === 200) {
      return response.data.url;
    } else {
      throw new Error("Failed to get media stream url");
    }
  } catch (error: any) {
    console.error("Error getting media stream url:", error);
    throw error;
  }
};
