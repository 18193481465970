import { useLoaderData, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth, useConfirmationDialog } from "../../context";
import { codeRequest, claimAttempt } from "../../api/claim";
import { getItemDetails } from "../../api/common";
import { VerificationParams, Item } from "../../types";
import styles from "./claim.module.css";
import { LoginModal, ConfirmationDialog } from "../../components";
import { ToastContainer, toast } from 'react-toastify';

type ItemAddressParams = {
  itemAddress: string;
};

const Claim: React.FC = () => {
  const claimed = useLoaderData() as boolean | null;
  const navigate = useNavigate();
  const { isLoggedIn, accountInfo, isSessionValid } = useAuth();
  const { itemAddress } = useParams<ItemAddressParams>();
  const [verificationCode, setVerificationCode] = useState("");
  const [item, setItem] = useState<Item | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const processClaim = async () => {
      if (claimed === null) {
        return
      }

      if(!claimed && itemAddress && isSessionValid) {
        try {
          await codeRequest(itemAddress);
        } catch (error) {
          console.log(error)
        }
      }
    };

    processClaim();
  }, [claimed, itemAddress, isSessionValid]);

  const {
    isConfirmationOpen,
    confirmationMessage,
    openConfirmationDialog,
    handleConfirm,
    closeConfirmationDialog
  } = useConfirmationDialog();

  useEffect(() => {
    const fetchItemDetails = async () => {
      if (!itemAddress) return;
      setLoading(true);

      try {
        const fetchedItem = await getItemDetails(itemAddress);
        setItem(fetchedItem); // Set the entire item object in the state
      } catch (error: any) {
        console.error('Error fetching item details', error);
        setItem(null); // Reset to null if there's an error
      } finally {
        setLoading(false);
      }
    };

    fetchItemDetails();
  }, [claimed, itemAddress]);

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!isSessionValid || !accountInfo || itemAddress === undefined) {
      return;
    }

    setIsSubmitting(true);

    const message = `Are you sure you want to claim this item?`;
    openConfirmationDialog(async () => {
      const obj: VerificationParams = {
        verificationCode,
        accountAddress: accountInfo.address,
        itemAddress
      };

      try {
        await toast.promise(
          claimAttempt(obj),
          {
            pending: 'Claiming...',
            success: 'Claim successful!',
            error: 'Claim rejected'
          }
        );
        // Wait for 1-2 seconds after showing the success toast before navigating
        setTimeout(() => {
          navigate('/library');
        }, 2000); // 2000 milliseconds = 2 seconds
      } catch (error) {
        console.error("Error during claim submission:", error);
      } finally {
        setIsSubmitting(false);
      }
    }, message);
  };


  if (claimed === null) {
    return (
      <div className={styles.claim}>
        <div className={styles.claimContainer}>
          <span>Scanned Item Not Found</span>
        </div>
      </div>
    );
  }

  if (claimed) {
    return (
      <div className={styles.claim}>
        <div className={`${styles.claimContainer} switcher`}>
          {loading
            ? <div className={`${styles.loadingGradient} loading-gradient`}></div>
            : (
              <div className={styles.imageContainer}>
                <img src={item?.uri} alt="Claimed Item" />
              </div>
            )}
          <section>
            <span>This item has already been claimed.</span><br />
          </section>
        </div>
      </div>
    );
  }

  if (!claimed && !isLoggedIn) {
    return (
      <div className={styles.claim}>
        <div className={`${styles.claimContainer} switcher`}>
          {loading
            ? <div className={`${styles.loadingGradient} loading-gradient`}></div>
            : (
              <div className={styles.imageContainer}>
                <img src={item?.uri} alt="Claimed Item" />
              </div>
            )}
          <section>
            <h2>Thanks for your early support!</h2>
            <span>Please log in to claim this item.</span><br />
            <button onClick={() => setIsLoginModalOpen(true)}>Login / Sign Up</button>
          </section>
        </div>
        {isLoginModalOpen && <LoginModal onClose={() => setIsLoginModalOpen(false)} />}
      </div>
    );
  }

  return (
    <div className={styles.claim}>
      <div className={`${styles.claimContainer}`} style={{display: "block"}}>
        <h2>Thanks for your early support!</h2>
        <span>Check the email used for your Shopify order for the claim code.</span>
        <div className={`${styles.infoContainer} switcher`}>
        {loading
          ? <div className={`${styles.loadingGradient} loading-gradient`}></div>
          : (
            <div className={styles.imageContainer}>
              <img src={item?.uri} alt="Claimed Item" />
            </div>
          )}
        <section>
          {accountInfo && <form onSubmit={handleFormSubmit}>
            <label htmlFor="verification-code">Claim Code:</label>
            <input
              type="text"
              id="verification-code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
            />
            <label htmlFor="verification-code">Item Name:</label>
            <span>{item ? item.name : 'Unable to fetch name'}</span>
            <label htmlFor="wallet-address">To wallet:</label>
            <span>{accountInfo.address}</span>
            <button type="submit" disabled={isSubmitting}>Claim</button>
          </form>}
        </section>
        </div>
      </div>
      <ConfirmationDialog
        message={confirmationMessage}
        isOpen={isConfirmationOpen}
        onConfirm={handleConfirm}
        onCancel={closeConfirmationDialog}
      />
      <ToastContainer />
    </div>
  );
}

export default Claim;
