import React, { useState } from 'react';
import styles from "./forms.module.css";
import { CreateAudioFormValues, handleNewAudio } from '../api/create';
import { Token } from '../types';
import { useAuth, useConfirmationDialog } from "../context";
import { ToastContainer, toast } from 'react-toastify';
import { ConfirmationDialog } from '../components';
import { validateName, validateDesc, validateUri, validateRoyaltyNumerator, validateRoyaltyDenominator, ErrorState } from './validation';

interface NewAudioFormProps {
  collectionName: string;
  collectionTokens: Token[];
  onClose: () => void;
  onUpdate: () => void;
  showToastSuccess: (message: string) => void;
  showToastError: (message: string) => void;
}

const NewAudioForm: React.FC<NewAudioFormProps> = ({
  collectionName,
  collectionTokens,
  onClose,
  onUpdate,
  showToastSuccess,
  showToastError
}) => {
  const { accountInfo, aptosWallet, isSessionValid } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState<CreateAudioFormValues>({
    collectionName,
    description: '',
    name: '',
    uri: '',
    royaltyNumerator: '0',
    royaltyDenominator: '0',
    royaltyAddress: accountInfo?.address ?? '',
    artist: '',
    duration: '',
    published: new Date().toISOString().slice(0, 10)
  });

  const [errors, setErrors] = useState<ErrorState>({
    nameError: '',
    descriptionError: '',
    uriError: '',
    numeratorError: '',
    denominatorError: '',
  });

  const {
    isConfirmationOpen,
    confirmationMessage,
    openConfirmationDialog,
    handleConfirm,
    closeConfirmationDialog
  } = useConfirmationDialog();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    let error: string | null = null;

    switch (name) {
      case "name":
          error = validateName(value, 30);
          break;
      case "description":
          error = validateDesc(value);
          break;
      case "uri":
          error = validateUri(value);
          break;
      case "numerator":
          error = validateRoyaltyNumerator(value, formValues.royaltyDenominator);
          break;
      case "denominator":
          error = validateRoyaltyDenominator(value, formValues.royaltyNumerator);
          break;
      default:
          break;
    }

    const errorKey = `${name}Error` as keyof ErrorState;
    if (error) {
      if (Object.keys(errors).includes(errorKey)) {
          setErrors(prevErrors => ({ ...prevErrors, [errorKey]: error }));
      }
    } else {
      if (Object.keys(errors).includes(errorKey)) {
          setErrors(prevErrors => ({ ...prevErrors, [errorKey]: "" })); // Clear the error
      }
    }

    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isSessionValid || !aptosWallet || !accountInfo) {
      toast.error("Your session is not valid.");
      return;
    }

    const tokenExists = collectionTokens.some(token => token.name.toLowerCase() === formValues.name.toLowerCase());
    if (tokenExists) {
        toast.error("A token in this collection already has this name.");
        return;
    }

    const formErrors = [
      validateName(formValues.name, 40),
      validateDesc(formValues.description),
      validateUri(formValues.uri),
      validateRoyaltyNumerator(formValues.royaltyNumerator, formValues.royaltyDenominator),
      validateRoyaltyDenominator(formValues.royaltyDenominator, formValues.royaltyNumerator),
    ];

    const formErrorMessages = ['name', 'description', 'uri', 'numerator', 'denominator'].reduce((acc, field, index) => {
      const errorKey = `${field}Error` as keyof ErrorState;
      acc[errorKey] = formErrors[index]; // This will set the respective error message or null
      return acc;
    }, {} as ErrorState);

    setErrors(formErrorMessages);

    if (Object.values(formErrorMessages).some(error => error !== null)) {
      toast.error("Please correct the errors before proceeding.");
      return;
    }

    const message = `Approve this audio?`;
    setIsSubmitting(true);
    openConfirmationDialog(async () => {
      try {
        await toast.promise(
          handleNewAudio(formValues, aptosWallet),
          {
            pending: 'Adding audio...',
            error: 'Error: Could not add audio.'
          }
        );
        showToastSuccess('Audio successfully added!');
        onUpdate();
        onClose();
      } catch (error: any) {
        console.error("Error during audio creation:", error);
        showToastError('An unexpected error occurred.');
      } finally {
        setIsSubmitting(false);
      }
    }, message);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.collectionForm}>
        <h3>Add New Audio</h3>
        <p style={{textDecoration: 'underline', fontSize: "medium"}}>*All inputs require ascii characters</p>
        <label style={{display: 'block'}}>Collection Name:</label>
        <div style={{fontSize: "medium", fontWeight:'bold', marginBlockEnd: '.5rem'}}>{collectionName}</div>
        <div className={`${styles.collectionFormBody} switcher`}>
          <section style={{width: '50%'}}>
            <label style={{display: 'block'}}>Audio Title:</label>
            <input
              type="text"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              style={{width:'100%'}}
            />
            {errors.nameError && <div style={{ color: 'red', fontSize: 'small'}}>{errors.nameError}</div>}
            <ul style={{fontSize: 'small'}}>
              <li>3-30 characters long</li>
            </ul>
            <label style={{display: 'block'}}>Artist:</label>
            <input
              type="text"
              name="artist"
              value={formValues.artist}
              onChange={handleChange}
              style={{width:'100%'}}
            />
            <ul style={{fontSize: 'small'}}>
              <li>0-128 characters long</li>
            </ul>
            <label style={{display: 'block'}}>Duration [XmXs]:</label>
            <input
              type="text"
              name="duration"
              value={formValues.duration}
              onChange={handleChange}
              style={{width:'100%', marginBlockEnd: '1rem'}}
            />
            <label style={{display: 'block'}}>Published [MM/DD/YYYY]:</label>
            <input
              type="date"
              name="published"
              value={formValues.published}
              onChange={handleChange}
              style={{marginBlockEnd: '1rem'}}
            />
            <label style={{display: 'block'}}>Description:</label>
            <input
              type="text"
              name="description"
              value={formValues.description}
              onChange={handleChange}
              style={{width:'100%'}}
            />
            {errors.descriptionError && <div style={{ color: 'red', fontSize: 'small'}}>{errors.descriptionError}</div>}
            <ul style={{fontSize: 'small'}}>
              <li>0-2048 characters long</li>
            </ul>
          </section>
          <section style={{width: '50%'}}>
            <label style={{display: 'block'}}>Image Url:</label>
            <input
              type="text"
              name="uri"
              value={formValues.uri}
              onChange={handleChange}
              style={{width:'100%'}}
            />
            {errors.uriError && <div style={{ color: 'red', fontSize: 'small'}}>{errors.uriError}</div>}
            <ul style={{fontSize: 'small'}}>
              <li>0-512 characters long</li>
              <li>Ex. https://xyz.arweave.net/xyz</li>
            </ul>
            <label style={{display: 'block'}}>Royalty Address:</label>
            <input
              type="text"
              name="royaltyAddress"
              value={formValues.royaltyAddress}
              onChange={handleChange}
              style={{width:'100%'}}
            />
            <ul style={{fontSize: 'small'}}>
              <li>Must be an Aptos account address</li>
            </ul>
            <label style={{display: 'block'}}>Royalty Denominator:</label>
            <input
              type="number"
              name="royaltyDenominator"
              value={formValues.royaltyDenominator}
              onChange={handleChange}
              min="0"
              max="100"
            />
            {errors.denominatorError && <div style={{ color: 'red', fontSize: 'small'}}>{errors.denominatorError}</div>}
            <ul style={{fontSize: 'small'}}>
              <li>Must be a number (0-100)</li>
              <li>Must be greater than numerator</li>
            </ul>
            <label style={{display: 'block'}}>Royalty Numerator:</label>
            <input
              type="number"
              name="royaltyNumerator"
              value={formValues.royaltyNumerator}
              onChange={handleChange}
              min="0"
              max="100"
            />
            {errors.numeratorError && <div style={{ color: 'red', fontSize: 'small'}}>{errors.numeratorError}</div>}
            <ul style={{fontSize: 'small'}}>
              <li>Must be a number (0-100)</li>
              <li>Must be less than denominator</li>
            </ul>
          </section>
        </div>
        <div className={styles.formBtns}>
          <button onClick={onClose}>Cancel</button>
          <button type="submit" disabled={isSubmitting}>Submit</button>
        </div>
      </form>
      <ConfirmationDialog
        message={confirmationMessage}
        isOpen={isConfirmationOpen}
        onConfirm={handleConfirm}
        onCancel={closeConfirmationDialog}
      />
      <ToastContainer />
    </>
  );
};

export default NewAudioForm;
