import React, { createContext, useState, useContext, useCallback, ReactNode } from 'react';

type ConfirmationDialogContextProps = {
  isConfirmationOpen: boolean;
  confirmationMessage: string;
  openConfirmationDialog: (action: () => void, message: string) => void;
  handleConfirm: () => void;
  closeConfirmationDialog: () => void;
};

const defaultContext: ConfirmationDialogContextProps = {
  isConfirmationOpen: false,
  confirmationMessage: '',
  openConfirmationDialog: () => {},
  handleConfirm: () => {},
  closeConfirmationDialog: () => {},
};

const ConfirmationDialogContext = createContext<ConfirmationDialogContextProps>(defaultContext);

export const useConfirmationDialog = () => {
  const context = useContext(ConfirmationDialogContext);
  if (!context) {
    throw new Error('useConfirmationDialog must be used within a ConfirmationDialogProvider');
  }
  return context;
}

type ConfirmationDialogProviderProps = {
  children: ReactNode;
};

export const ConfirmationDialogProvider: React.FC<ConfirmationDialogProviderProps> = ({ children }) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [pendingAction, setPendingAction] = useState<(() => void) | null>(null);

  const openConfirmationDialog = useCallback((action: () => void, message: string) => {
    setConfirmationMessage(message);
    setIsConfirmationOpen(true);
    setPendingAction(() => action);
  }, []);

  const handleConfirm = useCallback(() => {
    if (pendingAction) pendingAction();
    setIsConfirmationOpen(false);
    setPendingAction(() => null);
  }, [pendingAction]);

  const closeConfirmationDialog = useCallback(() => {
    setIsConfirmationOpen(false);
    setPendingAction(() => null);
  }, []);

  const value = {
    isConfirmationOpen,
    confirmationMessage,
    openConfirmationDialog,
    handleConfirm,
    closeConfirmationDialog
  };

  return (
    <ConfirmationDialogContext.Provider value={value}>
      {children}
    </ConfirmationDialogContext.Provider>
  );
}
