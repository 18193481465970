import { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../context';
import { NavLink } from 'react-router-dom';
import { NavigationItemProps } from "../../types";
import './hamburger-menu.css'
import { ExternalLinkIcon, CopyIcon, LinkIcon } from '../../assets';
import { copyToClipboard } from '../../utils';

export interface HamburgerMenuProps {
  menuItems: NavigationItemProps[];
  setIsLoginModalOpen: (open: boolean) => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ menuItems, setIsLoginModalOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLoggedIn, logout, accountInfo, balance } = useAuth();
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  const abbreviatedAddress = accountInfo ? `${accountInfo.address.slice(0, 5)}...${accountInfo.address.slice(-4)}` : '';

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      const hamburgerIcon = document.querySelector('.hamburger-icon');

      if (hamburgerIcon && hamburgerIcon.contains(event.target as Node)) {
        return;
      }

      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='hm-wrapper' style={{width: 'var(--s4)', paddingInlineEnd: '1.7rem', justifyContent: 'flex-end'}}>
      <div className={`hamburger-menu ${isOpen ? 'active' : ''}`} role="button" tabIndex={0} onClick={handleClick} onKeyPress={handleKeyPress}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem'}}>
          <div className="hamburger-icon" >
            <div className={`line ${isOpen ? 'line-open' : ''}`} />
            <div className={`line ${isOpen ? 'line-open mid' : ''}`} />
            <div className={`line ${isOpen ? 'line-open' : ''}`} />
          </div>
        </div>
        {isOpen && (
          <div>
            <div className="menu-list" ref={menuRef}>
            {isLoggedIn ? (
              <div className="account-info-hm">
                <div className="account-item">
                  Address: {abbreviatedAddress}
                  <CopyIcon className='copy-icon'
                    onClick={() => accountInfo && copyToClipboard(accountInfo.address)}/>
                  <a href={`https://explorer.aptoslabs.com/account/${accountInfo?.address}/transactions?network=testnet`} target="_blank" rel="noopener noreferrer">
                    <LinkIcon className='link-icon' />
                  </a>
                </div>
              <div className="account-item">Balance: {(Number(balance) / Math.pow(10, 8)).toFixed(5)} APT</div>
              <button className="account-item logout-hm" onClick={logout}>
                Logout
              </button>
            </div>
            ) : (
              <>
                <button className='login-hm' onClick={() => setIsLoginModalOpen(true)}>Login / Sign-up</button>
              </>
            )}
            {menuItems.map((item) => (
              item.text === 'Shop' ? (
                <a
                  href={item.link}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '.5rem',
                    whiteSpace: 'nowrap',
                  }}
                  className='menu-item'
                  target="_blank"
                  rel="noreferrer noopener"
                  key={item.text}
                >
                  {item.text} <ExternalLinkIcon />
                </a>
              ):(
                <NavLink to={item.link} className="menu-item" key={item.text}>
                  {item.text}
                </NavLink>
              )
            ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HamburgerMenu;
