import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../context';
import './account-dropdown.css';
import { UserIcon } from '../../assets';
import { NavigationItemProps } from "../../types";

export interface AccountMenuProps {
  nav: NavigationItemProps[];
}

const AccountDropdown: React.FC<AccountMenuProps> = ({nav}) => {
  const { logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);


  return (
    <div className="account-dropdown" ref={dropdownRef}>
      <button className="account-btn" onClick={() => setIsOpen(!isOpen)}>
        <UserIcon style={{alignSelf: 'center'}}/>
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          {nav.map((item) => (
            <div key={item.text} className="dropdown-item" onClick={() => setIsOpen(false)}>
              <NavLink to={item.link}>
                {item.text}
              </NavLink>
            </div>
          ))}
          <button className="dropdown-item logout-btn" onClick={() => { logout(); setIsOpen(false); }}>
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default AccountDropdown;
