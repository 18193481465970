import { useAuth } from '../../context';
import { useState } from 'react';
import { LoginModal, Nav, AccountDropdown, HamburgerMenu } from '../../components';
import { NavigationItemProps } from '../../types';
import { useNavigate } from 'react-router-dom';
import './header.css';

const Header: React.FC = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const navigateHome = () => {
    navigate('/');
  };

  const navigation: NavigationItemProps[] = [
    { link: '/library', text: 'Library'},
    { link: 'https://m1rror.com', text: 'Shop'},
  ];

  const accountNav: NavigationItemProps[] = [
    { link: '/account', text: 'Account'},
    { link: '/create', text: 'Create'},
  ];

  return (
    <header>
      <button className="logo" onClick={navigateHome}>M1RROR</button>
      <HamburgerMenu menuItems={navigation} setIsLoginModalOpen={setIsLoginModalOpen}/>
      <Nav menuItems={navigation}/>
      {isLoggedIn ? (
        <AccountDropdown nav={accountNav}/>
      ) : (
        <>
          <button className='login' onClick={() => setIsLoginModalOpen(true)}>Login / Sign-up</button>
          {isLoginModalOpen && <LoginModal onClose={() => setIsLoginModalOpen(false)} />}
        </>
      )}
    </header>
  );
};

export default Header;
