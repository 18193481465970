import React, { useState } from 'react';
import styles from "./forms.module.css";
import { Token } from '../types';
import { CreateMasterFormValues, handleNewMaster } from '../api/create';
import { useAuth, useConfirmationDialog } from "../context";
import { ToastContainer, toast } from 'react-toastify';
import { ConfirmationDialog } from '../components';

interface NewMasterFormProps {
  collectionName: string;
  collectionTokens: Token[];
  onClose: () => void;
  onUpdate: () => void;
  showToastSuccess: (message: string) => void;
  showToastError: (message: string) => void;
}

const NewMasterForm: React.FC<NewMasterFormProps> = ({
  collectionName,
  collectionTokens,
  onClose,
  onUpdate,
  showToastSuccess,
  showToastError
}) => {
  const { isSessionValid, accountInfo, aptosWallet } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [itemAddress, setItemAddress] = useState('');
  const [mediaAddresses, setMediaAddresses] = useState<string[]>([]);

  const {
    isConfirmationOpen,
    confirmationMessage,
    openConfirmationDialog,
    handleConfirm,
    closeConfirmationDialog
  } = useConfirmationDialog();

  const handleItemChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setItemAddress(event.target.value);
  };

  const handleMediaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const address = event.target.value;
    if (event.target.checked) {
      setMediaAddresses(prev => [...prev, address]);
    } else {
      setMediaAddresses(prev => prev.filter(addr => addr !== address));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isSessionValid || !aptosWallet || !accountInfo) {
      toast.error("Your session is not valid.");
      return;
    }

    const formValues: CreateMasterFormValues = {
      collectionName,
      itemAddress,
      mediaAddresses,
    };

    const message = `Approve this Master Edition?`;
    setIsSubmitting(true);
    openConfirmationDialog(async () => {
      try {
        await toast.promise(
          handleNewMaster(formValues, aptosWallet),
          {
            pending: 'Adding master...',
            error: 'Error: Could not add master.'
          }
        );
        showToastSuccess('Master successfully added!');
        onUpdate();
        onClose();
      } catch (error: any) {
        console.error("Error during master creation:", error);
        showToastError('An unexpected error occurred.');
      } finally {
        setIsSubmitting(false);
      }
    }, message);
  };

  const filteredItems = collectionTokens.filter(token => token.properties?.type === 'item');
  const filteredMedia = collectionTokens.filter(token =>
    !token.properties?.type?.startsWith('master') && token.properties?.type !== 'item'
  );

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.collectionForm}>
        <h3 style={{marginBlockEnd: '1rem'}}>Add New Master</h3>
        <div>
            <label style={{display: 'block'}}>Collection Name:</label>
            <div style={{fontSize: "medium", fontWeight: 'bold', marginBlockEnd: '.75rem'}}>{collectionName}</div>
            <label style={{display: 'block'}}>Choose Item:</label>
            <select value={itemAddress} onChange={handleItemChange}>
              <option value="">Select an item</option>
              {filteredItems.length > 0 ? (
                filteredItems.map(token => (
                <option key={token.address} value={token.address}>{token.name}</option>
              ))) : (
                <div className='box'>No available items</div>
              )}
            </select>
            <label style={{display: 'block'}}>Choose media files to attach:</label>
            {filteredMedia.length > 0 ? (
              filteredMedia.map(token => (
                <div key={token.address}>
                  <label>
                    <input type="checkbox" value={token.address} onChange={handleMediaChange} />
                    {token.name}
                  </label>
                </div>
              ))
            ) : (
              <div className='box'>No available media</div>
            )}
        </div>
        <div className={styles.formBtns}>
          <button onClick={onClose}>Cancel</button>
          <button type="submit" disabled={isSubmitting}>Submit</button>
        </div>
      </form>
      <ConfirmationDialog
        message={confirmationMessage}
        isOpen={isConfirmationOpen}
        onConfirm={handleConfirm}
        onCancel={closeConfirmationDialog}
      />
      <ToastContainer />
    </>
  );
};

export default NewMasterForm;
