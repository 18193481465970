import React, { useEffect, useRef } from 'react';

type AudioPlayerProps = {
  src: string;
  isPlaying: boolean;
  onPlayStateChange: (isPlaying: boolean) => void;
  showToastError: (message: string) => void;
};

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src, isPlaying, onPlayStateChange, showToastError }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (src && audioRef.current) {
      audioRef.current.src = src;
    }
    console.log('ran')
  }, [src]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play().catch((e) => showToastError('No audio file found'));
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying, showToastError]);

  useEffect(() => {
    const audioEl = audioRef.current;
    if (audioEl) {
      const handlePlay = () => onPlayStateChange(true);
      const handlePause = () => onPlayStateChange(false);

      // Add event listeners
      audioEl.addEventListener('play', handlePlay);
      audioEl.addEventListener('pause', handlePause);

      // Remove event listeners on cleanup
      return () => {
        audioEl.removeEventListener('play', handlePlay);
        audioEl.removeEventListener('pause', handlePause);
      };
    }
  }, [onPlayStateChange]);

  return (
    <audio controls controlsList="nodownload" ref={audioRef} />
  );
};

export default AudioPlayer;
