import { Outlet } from "react-router-dom";
import { Footer, Header } from "../components";

const Root: React.FC = () => {

  return (
    <div className="cover">
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default Root;
