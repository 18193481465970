import React, { useRef } from 'react';
import { useAuth } from '../../context';
import "./login-modal.css";

interface LoginModalProps {
  onClose: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ onClose }) => {
  const { login } = useAuth();
  const emailInputRef = useRef<HTMLInputElement>(null);

  const handleEmailLogin = async (email: string) => {
    try {
      await login(email);
      onClose();
    } catch (error) {
      console.error("Login error:", error);
      // Handle error as appropriate
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <button className="close-button" onClick={onClose}>X</button>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleEmailLogin(emailInputRef.current?.value || "");
          }}
        >
          <h3>Please sign up or login</h3>
          <div className="row">
            <input className="email-input" type="email" name="email" placeholder="Enter your email" ref={emailInputRef} required />
            <button className='send-button' type="submit">Send</button>
          </div>
        </form>
        {/* Future: other login methods... */}
      </div>
    </div>
  );
};

export default LoginModal;
