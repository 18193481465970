import React from 'react';
import { Token } from '../../types';
import styles from './collection-tokens-table.module.css';
import { UploadIcon, PlayIcon, PauseIcon, CopyIcon } from '../../assets';
import { LoadingSpinner } from '../../components';
import { copyToClipboard } from '../../utils';

interface CollectionTokensTableProps {
  tokens: Token[];
  onUploadClick: (token: Token) => void;
  onPlayClick: (token: Token) => void;
  onPauseClick: () => void;
  loadingMedia: string | null;
  activeMedia: string | null;
  isPlaying: boolean;
}

const CollectionTokensTable: React.FC<CollectionTokensTableProps> = ({
  tokens,
  onUploadClick,
  onPlayClick,
  onPauseClick,
  loadingMedia,
  activeMedia,
  isPlaying
}) => {
  return (
    <table className={styles.tokensTable}>
      <thead>
        <tr>
          <th>Actions</th>
          <th>Type</th>
          <th>Title</th>
          <th>Address</th>
        </tr>
      </thead>
      <tbody>
        {tokens.map((token) => (
          <tr key={token.address}>
            <td style={{display: 'flex', gap: '.5rem', alignItems: 'center'}}>
              {token.properties?.type !== 'master_item' && token.properties?.type !== 'item' && <UploadIcon className={styles.uploadIcon} onClick={() => onUploadClick(token)} />}
              {token.properties?.type === 'master_item' && <CopyIcon onClick={() => copyToClipboard(token.address ?? "")} />}
              {token.uploadFound && (
                <>
                  {loadingMedia === token.address ? (
                    <LoadingSpinner />
                  ) : activeMedia === token.address ? (
                    isPlaying ? (
                      <PauseIcon onClick={onPauseClick} />
                    ) : (
                      <PlayIcon onClick={() => onPlayClick(token)} />
                    )
                  ) : (
                    <PlayIcon className={styles.playIcon} onClick={() => onPlayClick(token)} />
                  )}
                </>
              )}            </td>
            <td>{token.properties?.type}</td>
            <td>{token.name}</td>
            <td>
              <a href={`https://explorer.aptoslabs.com/object/${token.address}?network=testnet`} target="_blank" rel="noopener noreferrer">
                {`${token.address?.slice(0, 15)}...${token.address?.slice(-15)}`}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default CollectionTokensTable;
