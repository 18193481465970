import React from 'react';
import './loading-card.css';

const LoadingCard: React.FC = () => {
  return (
    <div className="loading-card">
      <div className="loading-image"></div>
      <div className="loading-text"></div>
      <div className="loading-text small"></div>
    </div>
  );
};

export default LoadingCard;
