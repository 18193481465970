import { Item } from '../../types';
import React, { useState } from 'react';
import placeholder from '../../assets/recoop-silver-pass-v2.png';
import "./item-card.css";

interface ItemCardProps {
  item: Item;
  onImageLoad: () => void;
  isLoading: boolean;
}

const ItemCard: React.FC<ItemCardProps> = ({ item, onImageLoad, isLoading }) => {
  const [imageUrl, setImageUrl] = useState(item.uri ?? placeholder);

  const handleImageError = () => {
    setImageUrl(placeholder);
    onImageLoad();
  };

  return (
    <div className={`item-card ${isLoading ? 'loading' : ''}`} style={{marginBlockEnd: '1rem'}}>
      <div className="image">
        <img src={imageUrl} alt={item.name} onLoad={onImageLoad} onError={handleImageError}/>
      </div>
      <div className="details">
        <h5 style={{marginBlock: 0, paddingInlineStart: '.5rem'}}>{item.name}</h5>
        <p style={{marginBlock: 0, fontSize: '.75rem', paddingInlineStart: '.5rem'}}>{item.collection}</p>
      </div>
    </div>
  );
};

export default ItemCard;
