import { useLoaderData, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../../context";
import { LoginModal  } from "../../components";
import { getItemDetails } from "../../api/common";
import styles from "./m1rror.module.css";
import { Item } from "../../types";

type ItemAddressParams = {
  itemAddress: string;
};

const M1rror: React.FC = () => {
  const claimed = useLoaderData() as boolean | null;
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const { itemAddress } = useParams<ItemAddressParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [item, setItem] = useState<Item | null>(null);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  useEffect(() => {
    const fetchItemDetails = async () => {
      if (!itemAddress) return;
      setLoading(true);

      try {
        const fetchedItem = await getItemDetails(itemAddress);
        setItem(fetchedItem); // Set the entire item object in the state
      } catch (error: any) {
        console.error('Error fetching item details', error);
        setItem(null); // Reset to null if there's an error
      } finally {
        setLoading(false);
      }
    };

    fetchItemDetails();
  }, [claimed, itemAddress]);

  const handleClaimClick = () => {
    if (itemAddress) {
      navigate(`/claim/${itemAddress}`);
    } else {
      console.error("Item address is undefined");
    }
  };

  // Item address does not exist
  if (claimed === null) {
    return (
      <div className={styles.m1rror}>
        <div className={styles.m1rrorContainer}>
          <span>Scanned Item Not Found</span>
        </div>
      </div>
    );
  }

  // item claimed and user not logged in
  if (!claimed) {
    return (
      <div className={styles.m1rror}>
        <div className={`${styles.m1rrorContainer} switcher`}>
          {loading
          ? <div className={`${styles.loadingGradient} loading-gradient`}></div>
          : (
            <div className={styles.imageContainer}>
              <img src={item?.uri} alt="Claimed Item" />
            </div>
          )}
          <section>
            <h2>You scanned a M1RROR item!</h2>
            <span>This item needs to be claimed before it can be M1RROR'd.</span><br />
            <span>Are you trying to claim it?</span><br />
            <button onClick={handleClaimClick}>Claim Page</button>
          </section>
        </div>
      </div>
    );
  }

  // item claimed and user not logged in
  if (claimed && !isLoggedIn) {
    return (
      <div className={styles.m1rror}>
        <div className={`${styles.m1rrorContainer} switcher`}>
          {loading
          ? <div className={`${styles.loadingGradient} loading-gradient`}></div>
          : (
            <div className={styles.imageContainer}>
              <img src={item?.uri} alt="Claimed Item" />
            </div>
          )}
          <section>
            <h2>You scanned a M1RROR item!</h2>
            <span>Please log in to M1RROR this item.</span><br />
            <button onClick={() => setIsLoginModalOpen(true)}>Login / Sign Up</button>
          </section>
        </div>
        {isLoginModalOpen && <LoginModal onClose={() => setIsLoginModalOpen(false)} />}
      </div>
    );
  }

  return (
    <div className={styles.m1rror}>
        <div className={styles.m1rrorOuter}>
          <div className={`${styles.m1rrorContainer} ${styles.item}`}>
            <h2>You scanned a M1RROR item!</h2>
            <section className={`${styles.m1rrorItem}`}>
              {loading
                ? <div className={`${styles.loadingGradient} loading-gradient`}></div>
                : (
                  <div className={styles.imageContainer}>
                    <img src={item?.uri} alt="Claimed Item" />
                  </div>
                )}
              <div className={styles.m1rrorItemDetails}>
                <span>The M1RROR feature is not yet available.</span><br />
              </div>
            </section>
          </div>
        </div>
    </div>
  );

};

export default M1rror;
