import styles from "./account.module.css";
import { useAuth } from '../../context';
import { CopyIcon, LinkIcon } from '../../assets';
import { copyToClipboard } from '../../utils';


const Account: React.FC = () => {
  const { accountInfo, balance } = useAuth();
  const abbreviatedAddress = `${accountInfo?.address.slice(0, 5)}...${accountInfo?.address.slice(-4)}`;


  return (
    <>
      <div className={styles.accountInfo}>
        <h2>Account</h2>
        <div className={styles.address}>Address: {abbreviatedAddress}
          <CopyIcon className={styles.copyIcon}
            onClick={() => accountInfo && copyToClipboard(accountInfo.address)}/>
          <a href={`https://explorer.aptoslabs.com/account/${accountInfo?.address}/transactions?network=testnet`} target="_blank" rel="noopener noreferrer">
            <LinkIcon className={styles.linkIcon} />
          </a>
        </div>
        <div>Balance: {(Number(balance) / Math.pow(10, 8)).toFixed(5)} APT</div>
        <div>
          <span className={styles.headline}>Import this account to a web3 wallet using your private key:</span>
          <span>xxxxxxxxxxxxxxxxx [view]</span>
        </div>
      </div>

    </>
  )
}

export default Account;
