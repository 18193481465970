import styles from "./create.module.css";
import { useAuth } from '../../context';
import { NewCreatorForm, NewCollectionForm, NewAudioForm, NewUploadForm, NewItemForm, NewMasterForm, ResubmitCreatorForm } from '../../forms';
import { CopyIcon, LinkIcon } from '../../assets';
import { copyToClipboard } from '../../utils';
import { AudioPlayer, LoginModal } from "../../components";
import { useEffect, useState, useCallback, ChangeEvent } from "react";
import { checkForCreator, getCreatorInfo, Creator, getCollectionTokens, Collection, checkMedia } from "../../api/create";
import { Token } from "../../types";
import CollectionTokensTable from "./collection-tokens-table";
import { ToastContainer, toast } from 'react-toastify';

const FormTypes = {
  NONE: "NONE",
  NEW_CREATOR: "NEW_CREATOR",
  NEW_COLLECTION: "NEW_COLLECTION",
  NEW_AUDIO: "NEW_AUDIO",
  NEW_UPLOAD: "NEW_UPLOAD",
  NEW_ITEM: "NEW_ITEM",
  NEW_MASTER: "NEW_MASTER",
  RESUBMIT_CREATOR: "RESUBMIT_CREATOR",
};


const Create: React.FC = () => {
  const { accountInfo, balance, requestMedia, isLoggedIn, authLoading, isSessionValid } = useAuth();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const abbreviatedAddress = `${accountInfo?.address.slice(0, 5)}...${accountInfo?.address.slice(-4)}`;
  const [creatorExists, setCreatorExists] = useState<boolean>(false);
  const [creatorInfo, setCreatorInfo] = useState<Creator | null>(null);
  const [collectionTokens, setCollectionTokens] = useState<{ [collectionAddress: string]: Token[] }>({});
  const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null);
  const [selectedToken, setSelectedToken] = useState<Token | null>();
  const [activeForm, setActiveForm] = useState<string>(FormTypes.NONE);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [collectionLoading, setCollectionLoading] = useState(false);

  const [currentSrc, setCurrentSrc] = useState<string>("");
  const [loadingMedia, setLoadingMedia] = useState<string | null>(null);
  const [activeMedia, setActiveMedia] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const showToastSuccess = (message: string) => {
    toast.success(message);
  };

  const showToastError = (message: string) => {
      toast.error(message);
  };

  const fetchAndUpdateCreatorInfo = useCallback(async () => {
    if (!accountInfo) return;

    try {
      const doesCreatorExist = await checkForCreator(accountInfo.address);
      setCreatorExists(doesCreatorExist);

      if (doesCreatorExist) {
        const creator = await getCreatorInfo(accountInfo.address);
        setCreatorInfo(creator);
      } else {
        setCreatorInfo(null);
      }
    } catch (error) {
      console.error('Error fetching or updating creator info', error);
    }
  }, [accountInfo]);

  useEffect(() => {
    fetchAndUpdateCreatorInfo();
  }, [fetchAndUpdateCreatorInfo]);


  const renderActiveForm = () => {
    switch (activeForm) {
      case FormTypes.NEW_CREATOR:
        return <NewCreatorForm
                  onClose={() => setActiveForm(FormTypes.NONE)}
                  onUpdate={fetchAndUpdateCreatorInfo}
                  showToastSuccess={showToastSuccess}
                  showToastError={showToastError}
                />;
      case FormTypes.NEW_COLLECTION:
        return <NewCollectionForm
                  collections={creatorInfo?.collections ?? []}
                  onClose={() => setActiveForm(FormTypes.NONE)}
                  onUpdate={fetchAndUpdateCreatorInfo}
                  showToastSuccess={showToastSuccess}
                  showToastError={showToastError}
                />;
      case FormTypes.NEW_AUDIO:
        if (selectedCollection) {
          return (
            <NewAudioForm
              collectionName={selectedCollection.name}
              collectionTokens={collectionTokens[selectedCollection.address]}
              onClose={() => setActiveForm(FormTypes.NONE)}
              onUpdate={handleCreationSuccess}
              showToastSuccess={showToastSuccess}
              showToastError={showToastError}
            />
          );
        } else {
          return null;
        }
      case FormTypes.NEW_UPLOAD:
        if (selectedToken) {
          return (
            <NewUploadForm
              token={selectedToken}
              replace={selectedToken.uploadFound}
              onClose={() => setActiveForm(FormTypes.NONE)}
              onUpdate={handleMediaUploadSuccess}
              showToastSuccess={showToastSuccess}
              showToastError={showToastError}
            />
          );
        } else {
          return null;
        }
      case FormTypes.NEW_ITEM:
        if (selectedCollection) {
          return (
            <NewItemForm
              collectionName={selectedCollection.name}
              collectionTokens={collectionTokens[selectedCollection.address]}
              onClose={() => setActiveForm(FormTypes.NONE)}
              onUpdate={handleCreationSuccess}
              showToastSuccess={showToastSuccess}
              showToastError={showToastError}
            />
          );
        } else {
          return null;
        }
      case FormTypes.NEW_MASTER:
        if (selectedCollection) {
          return (
            <NewMasterForm
              collectionName={selectedCollection.name}
              collectionTokens={collectionTokens[selectedCollection.address]}
              onClose={() => setActiveForm(FormTypes.NONE)}
              onUpdate={handleCreationSuccess}
              showToastSuccess={showToastSuccess}
              showToastError={showToastError}
            />
          );
        } else {
          return null;
        }
      case FormTypes.RESUBMIT_CREATOR:
        return <ResubmitCreatorForm
          onClose={() => setActiveForm(FormTypes.NONE)}
          onUpdate={fetchAndUpdateCreatorInfo}
          showToastSuccess={showToastSuccess}
          showToastError={showToastError}
        />;
      default:
        return null; // No form is active
    }
  };

  const handleUpload = (token: Token) => {
    setSelectedToken(token);
    setActiveForm(FormTypes.NEW_UPLOAD);
  };

  const handlePlay = async (token: Token) => {
    if (!isSessionValid) {
      toast.error("You must be logged in to play this media.");
      return;
    }

    if (token.address && token.address !== activeMedia) {
      setLoadingMedia(token.address);
      setActiveMedia(null);

      try {
        let newSrc;

        if(token.properties?.masterItem){
          newSrc = await requestMedia(token.address, token.properties.masterItem);
        } else {
          newSrc = await requestMedia(token.address, token.address);
        }

        if (newSrc) {
          setCurrentSrc(newSrc);
          setIsPlaying(true);
          setActiveMedia(token.address);
        } else {
          console.log('Could not load');
          toast.error("Error loading media source. Please try again later.");
        }
      } catch (error: any) {
        console.error("Error fetching media:", error);
        toast.error("Error loading media. Please try again later.");
      } finally {
        setLoadingMedia(null);
      }
    } else if (isPlaying) {
      handlePause();
    } else {
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    const fetchAndCheckTokens = async () => {
      if (!selectedCollection) return;
      setCollectionLoading(true);

      try {
        const tokens = await getCollectionTokens(selectedCollection.address);
        const tokenAddresses = tokens.map(token => token.address ?? "");
        const mediaExistenceResults = await checkMedia(tokenAddresses);

        const updatedTokens = tokens.map(token => {
          const mediaResult = mediaExistenceResults.find(result => result.tokenAddress === token.address);

          return {
            ...token,
            uploadFound: mediaResult ? mediaResult.exists : false,
          };
        });

        setCollectionTokens(prevState => ({
          ...prevState,
          [selectedCollection.address]: updatedTokens,
        }));
      } catch (error) {
        console.error("Error fetching tokens or checking media existence:", error);
      }finally {
        setCollectionLoading(false);
      }
    };

    fetchAndCheckTokens();
  }, [selectedCollection, updateTrigger]);

  const handleCreationSuccess = () => {
    setUpdateTrigger(prev => !prev);
  };

  const handleMediaUploadSuccess = (mediaAddress: string) => {
    if (!selectedCollection) return;

    setCollectionTokens(prevTokens => {
      const tokens = prevTokens[selectedCollection?.address];
      const updatedTokens = tokens.map(token =>
        token.address === mediaAddress ? { ...token, uploadFound: true } : token
      );
      return {
        ...prevTokens,
        [selectedCollection?.address]: updatedTokens,
      };
    });
  };

  const handleCollectionSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const address = event.target.value;
    const selectedCollectionObj = creatorInfo?.collections.find(collection => collection.address === address);
    setSelectedCollection(selectedCollectionObj || null);
    setActiveForm(FormTypes.NONE)
  };

  const hasItemTokens = selectedCollection
                          && collectionTokens[selectedCollection.address]
                          && collectionTokens[selectedCollection.address]
                              .some(token => token.properties?.type === 'item');
  const hasUploadedMedia = selectedCollection
                          && collectionTokens[selectedCollection.address]
                          && collectionTokens[selectedCollection.address]
                              .some(token => token.uploadFound);

  const renderCreatorInfo = () => {
    if (activeForm === FormTypes.NEW_CREATOR) {
      return null;
    }

    if (authLoading) {
      return <div>Loading creator info...</div>;
    } else if (creatorExists && creatorInfo) {
      return (
        <>
        <div className={styles.collectionHeader}>
          <section>
            <h3>Choose Collection:</h3>
            <select onChange={handleCollectionSelect} value={selectedCollection?.address}>
              <option value="">Select a Collection</option>
              {creatorInfo?.collections.map(collection => (
                <option key={collection.address} value={collection.address}>{collection.name}</option>
              ))}
            </select>
          </section>
          <section className={styles.actionButtons}>
            <button
              disabled={!selectedCollection}
              className={!selectedCollection ? styles.buttonDisabled : ''}
              onClick={() => setActiveForm(FormTypes.NEW_AUDIO)}
            >
              + Audio
            </button>
            <button
              disabled={!selectedCollection}
              className={!selectedCollection ? styles.buttonDisabled : ''}
              onClick={() => setActiveForm(FormTypes.NEW_ITEM)}
            >
              + Item
            </button>
            <button
              disabled={!hasItemTokens}
              className={!hasItemTokens ? styles.buttonDisabled : ''}
              onClick={() => setActiveForm(FormTypes.NEW_MASTER)}
            >
              + Master
            </button>
          </section>
        </div>
        {selectedCollection ? (
          collectionLoading ? (
            <div className="loading-gradient">Loading collection...</div>
          ) : Array.isArray(collectionTokens[selectedCollection.address])
          && collectionTokens[selectedCollection.address].length > 0 ? (
            <CollectionTokensTable
              tokens={collectionTokens[selectedCollection.address]}
              onUploadClick={handleUpload}
              onPlayClick={handlePlay}
              onPauseClick={handlePause}
              loadingMedia={loadingMedia}
              activeMedia={activeMedia}
              isPlaying={isPlaying}
            />
          ) : (
            <div className="box" style={{ textAlign: 'center', marginTop: '20px' }}>
              No tokens found in this collection.
            </div>
          )
        ) : (
          <div className="box" style={{ textAlign: 'center', marginTop: '20px' }}>
            Select a collection from the dropdown.
          </div>
        )}
        </>
      );
    } else if (activeForm === FormTypes.NONE && isSessionValid) {
      return (
        <div className={styles.addUser}>
          <p>Want to start your own collection?</p>
          <div style={{display: 'flex', gap: '1rem', justifyContent: 'center'}}>
            <button onClick={() => setActiveForm(FormTypes.NEW_CREATOR)}>
              Claim Username
            </button>
            <button onClick={() => setActiveForm(FormTypes.RESUBMIT_CREATOR)}>
              Resubmit Username
            </button>
          </div>
        </div>
      );
    } else if (!isLoggedIn) {
      return (
        <div className={styles.addUser}>
          <p>Please login to view this page</p>
          <button onClick={() => setIsLoginModalOpen(true)}>Login</button>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.create}>
      <div className={`switcher ${styles.creatorHeader}`}>
        <section>
          <h2>Creation starts here...</h2>
          {accountInfo &&
            <>
              <div className={styles.details}>
                Address: {abbreviatedAddress}
                <CopyIcon className={styles.copyIcon} onClick={() => accountInfo && copyToClipboard(accountInfo.address)} />
                <a href={`https://explorer.aptoslabs.com/account/${accountInfo?.address}/transactions?network=testnet`} target="_blank" rel="noopener noreferrer">
                  <LinkIcon className={styles.linkIcon} />
                </a>
              </div>
              <div className={styles.details}>Balance: {balance === BigInt(0) ? '0' : (Number(balance) / Math.pow(10, 8)).toFixed(5)} APT</div>
              {creatorInfo && <div className={styles.details}>Username: {creatorInfo.username}</div>}
            </>
          }
        </section>
        <section className={styles.buttonSection}>
          {creatorInfo && <div className={styles.actionButtons}>
            <button onClick={() => setActiveForm(FormTypes.NEW_COLLECTION)}>+ Collection</button>
          </div>}
        </section>
      </div>
      <div>
        {renderActiveForm()}
      </div>
      {renderCreatorInfo() && (
        <div className={styles.creatorStatus}>
          {renderCreatorInfo()}
        </div>
      )}
      {hasUploadedMedia &&
        <div className={styles.audioPlayerContainer}>
          <AudioPlayer
            src={currentSrc}
            isPlaying={isPlaying}
            onPlayStateChange={setIsPlaying}
            showToastError={showToastError}/>
        </div>}
      {isLoginModalOpen && <LoginModal onClose={() => setIsLoginModalOpen(false)} />}
      <ToastContainer />
    </div>
  );
}

export default Create;
