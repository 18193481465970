import React, { useState, useEffect } from "react";
import styles from "./items.module.css";
import { ItemCard, LoadingCard } from "../../../components";
import { Item } from "../../../types";
import { youngMan } from "../../../assets";

interface ItemsProps {
  items: Item[];
  loading: boolean;
}

const Items: React.FC<ItemsProps> = ({items, loading}) => {
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  useEffect(() => {
    const originalBackgroundColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = 'white';

    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);

  useEffect(() => {
    if (items.length > 0 && loadedImagesCount === items.length) {
      setAllImagesLoaded(true);
    }
  }, [loadedImagesCount, items.length]);

  const handleImageLoad = () => {
    setLoadedImagesCount(current => current + 1);
  };

  return (
    <div style={{maxWidth: '1120px', marginInline: 'auto'}}>
      <section className={`${styles.itemHeader} switcher`}>
        <img src={youngMan} alt="young-guy-trying-sneakers" className={styles.headingImage}/>
        <div className={styles.itemsInfo}>
          <h1>My Items</h1>
          <p>{items.length} items</p>
        </div>
      </section>
      {loading ? (
        <div className="grid">
          <LoadingCard />
          <LoadingCard />
        </div>
      ) : items.length === 0 ? (
        <div className={`box ${styles.noneFound}`}>
          <h2>No items found.</h2>
        </div>
      ) : (
        <div className="grid">
          {items.map(item => (
            <ItemCard
             key={item.address}
             item={item}
             onImageLoad={handleImageLoad}
             isLoading={!allImagesLoaded}/>
          ))}
        </div>
      )}
    </div>
  );
};

export default Items;
