import React, { useState } from 'react';
import styles from "./forms.module.css";
import { CreateCollectionFormValues, handleNewCollection, Collection } from '../api/create';
import { useAuth, useConfirmationDialog } from "../context";
import { ToastContainer, toast } from 'react-toastify';
import { ConfirmationDialog } from '../components';
import { validateName, validateDesc, validateUri, validateRoyaltyNumerator, validateRoyaltyDenominator, ErrorState } from './validation';

interface NewCollectionFormProps {
  collections: Collection[];
  onClose: () => void;
  onUpdate: () => Promise<void>;
  showToastSuccess: (message: string) => void;
  showToastError: (message: string) => void;
}

const NewCollectionForm: React.FC<NewCollectionFormProps> = ({
  collections,
  onClose,
  onUpdate,
  showToastSuccess,
  showToastError
}) => {
  const { isSessionValid, accountInfo, aptosWallet } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState<CreateCollectionFormValues>({
    name: '',
    description: '',
    uri: '',
    royaltyNumerator: '0',
    royaltyDenominator: '0',
  });

  const [errors, setErrors] = useState<ErrorState>({
    nameError: '',
    descriptionError: '',
    uriError: '',
    numeratorError: '',
    denominatorError: '',
  });

  const {
    isConfirmationOpen,
    confirmationMessage,
    openConfirmationDialog,
    handleConfirm,
    closeConfirmationDialog
  } = useConfirmationDialog();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    let error: string | null = null;

    switch (name) {
      case "name":
          error = validateName(value, 40);
          break;
      case "description":
          error = validateDesc(value);
          break;
      case "uri":
          error = validateUri(value);
          break;
      case "numerator":
          error = validateRoyaltyNumerator(value, formValues.royaltyDenominator);
          break;
      case "denominator":
          error = validateRoyaltyDenominator(value, formValues.royaltyNumerator);
          break;
      default:
          break;
    }

    const errorKey = `${name}Error` as keyof ErrorState;
    if (error) {
      if (Object.keys(errors).includes(errorKey)) {
          setErrors(prevErrors => ({ ...prevErrors, [errorKey]: error }));
      }
    } else {
      if (Object.keys(errors).includes(errorKey)) {
          setErrors(prevErrors => ({ ...prevErrors, [errorKey]: "" })); // Clear the error
      }
    }

    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isSessionValid || !aptosWallet || !accountInfo) {
      toast.error("Your session is not valid.");
      return;
    }

    const collectionExists = collections.some(collection => collection.name.toLowerCase() === formValues.name.toLowerCase());
    if (collectionExists) {
      toast.error("A collection with this name already exists.");
        return;
    }

    const formErrors = [
      validateName(formValues.name, 40),
      validateDesc(formValues.description),
      validateUri(formValues.uri),
      validateRoyaltyNumerator(formValues.royaltyNumerator, formValues.royaltyDenominator),
      validateRoyaltyDenominator(formValues.royaltyDenominator, formValues.royaltyNumerator),
    ];

    const formErrorMessages = ['name', 'description', 'uri', 'numerator', 'denominator'].reduce((acc, field, index) => {
      const errorKey = `${field}Error` as keyof ErrorState;
      acc[errorKey] = formErrors[index];
      return acc;
    }, {} as ErrorState);

    setErrors(formErrorMessages);

    if (Object.values(formErrorMessages).some(error => error !== null)) {
      toast.error("Please correct the errors before proceeding.");
      return;
    }

    const message = `Approve this collection?`;
    setIsSubmitting(true);
    openConfirmationDialog(async () => {
      try {
        await toast.promise(
          handleNewCollection(formValues, accountInfo.address, aptosWallet),
          {
            pending: 'Adding collection...',
            error: 'Error: Could not add collection.'
          }
        );
        showToastSuccess('Collection successfully added!');
        await onUpdate();
        onClose();
      } catch (error: any) {
        console.error("Error during collection creation:", error);
        showToastError('An unexpected error occurred.');
      } finally {
        setIsSubmitting(false);
      }
    }, message);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.collectionForm}>
        <h3>Add New Collection</h3>
        <p style={{textDecoration: 'underline', fontSize: "medium"}}>*All inputs require ascii characters</p>
        <div className={`${styles.collectionFormBody} switcher`}>
          <section style={{width: '50%'}}>
            <label style={{display: 'block'}}>Name:</label>
            <input
              type="text"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              style={{width:'100%'}}
            />
            {errors.nameError && <div style={{ color: 'red', fontSize: 'small'}}>{errors.nameError}</div>}
            <ul style={{fontSize: 'small'}}>
                <li>Must be 3-40 characters long</li>
              </ul>
            <label style={{display: 'block'}}>Description:</label>
            <input
              type="text"
              name="description"
              value={formValues.description}
              onChange={handleChange}
              style={{width:'100%'}}
            />
            {errors.descriptionError && <div style={{ color: 'red', fontSize: 'small'}}>{errors.descriptionError}</div>}
            <ul style={{fontSize: 'small'}}>
              <li>0-2048 characters long</li>
            </ul>
            <label style={{display: 'block'}}>Image Url:</label>
            <input
              type="text"
              name="uri"
              value={formValues.uri}
              onChange={handleChange}
              style={{width:'100%'}}
            />
            {errors.uriError && <div style={{ color: 'red', fontSize: 'small'}}>{errors.uriError}</div>}
            <ul style={{fontSize: 'small'}}>
              <li>0-512 characters long</li>
              <li>Ex. https://xyz.arweave.net/xyz</li>
            </ul>
          </section>
          <section style={{width: '50%'}}>
            <label style={{display: 'block'}}>Royalty Denominator:</label>
            <input
              type="number"
              name="royaltyDenominator"
              value={formValues.royaltyDenominator}
              onChange={handleChange}
              min="0"
              max="100"
            />
            {errors.denominatorError && <div style={{ color: 'red', fontSize: 'small'}}>{errors.denominatorError}</div>}
            <ul style={{fontSize: 'small'}}>
              <li>Must be a number (0-100)</li>
              <li>Must be greater than numerator</li>
            </ul>
            <label style={{display: 'block'}}>Royalty Numerator:</label>
            <input
              type="number"
              name="royaltyNumerator"
              value={formValues.royaltyNumerator}
              onChange={handleChange}
              min="0"
              max="100"
            />
            {errors.numeratorError && <div style={{ color: 'red', fontSize: 'small'}}>{errors.numeratorError}</div>}            <ul style={{fontSize: 'small'}}>
              <li>Must be a number (0-100)</li>
              <li>Must be less than denominator</li>
            </ul>
          </section>
        </div>
        <div className={styles.formBtns}>
          <button onClick={onClose}>Cancel</button>
          <button type="submit" disabled={isSubmitting}>Submit</button>
        </div>
      </form>
      <ConfirmationDialog
        message={confirmationMessage}
        isOpen={isConfirmationOpen}
        onConfirm={handleConfirm}
        onCancel={closeConfirmationDialog}
      />
      <ToastContainer />
    </>
  );
};

export default NewCollectionForm;
