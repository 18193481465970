import React, { createContext, useContext, useEffect, useState, ReactNode, useMemo } from "react";
import { Magic } from "magic-sdk";
import { AptosExtension, MagicAptosWallet } from "@magic-ext/aptos";
import { AccountInfo, NetworkInfo } from "@aptos-labs/wallet-adapter-core";
import { MAGIC_LINK, TEST_NODE } from "../constants";
import { AptosClient, CoinClient } from "aptos";
import { getStreamUrl } from "../api/files";

interface AuthContextProps {
  isLoggedIn: boolean;
  authLoading: boolean;
  balance: BigInt;
  login: (email: string) => Promise<void>;
  logout: () => Promise<void>;
  aptosWallet: MagicAptosWallet | null;
  accountInfo: AccountInfo | null;
  networkInfo: NetworkInfo | null;
  requestMedia: (mediaAddress: string, itemAddress: string) => Promise<string | null>;
  isSessionValid: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [aptosWallet, setAptosWallet] = useState<MagicAptosWallet | null>(null);
  const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);
  const [networkInfo, setNetworkInfo] = useState<NetworkInfo | null>(null);
  const [balance, setBalance] = useState(BigInt(0));
  const [authLoading, setAuthLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isSessionValid, setIsSessionValid] = useState(false);

  const magic = useMemo(() => new Magic(MAGIC_LINK, {
    extensions: [
      new AptosExtension({ nodeUrl: TEST_NODE }),
    ],
  }), []);

  useEffect(() => {
    const authenticateAndSetUser = async () => {
        setAuthLoading(true);
        let magicIsLoggedIn;
        try {
            magicIsLoggedIn = await magic.user.isLoggedIn();
            if (!magicIsLoggedIn) {
                await magic.auth.loginWithCredential();
                magicIsLoggedIn = true;
                console.log("Silent login succeeded");
            }

            if (magicIsLoggedIn) {
                const magicAptosWallet = new MagicAptosWallet(magic, {
                    connect: async () => await magic.aptos.getAccountInfo(),
                });

                const [accountInfo, networkInfo] = await Promise.all([
                    magicAptosWallet.account(),
                    magicAptosWallet.network()
                ]);

                setIsLoggedIn(true);
                setIsSessionValid(true);
                setAptosWallet(magicAptosWallet);
                setAccountInfo(accountInfo);
                setNetworkInfo(networkInfo);
                await getBalance(accountInfo.address);
            }
        } catch (error) {
            console.error("Error during authentication or account setup:", error);
        } finally {
            setAuthLoading(false);
        }
    };

    authenticateAndSetUser();
  }, [magic]);

  useEffect(() => {
    const checkSession = async () => {
        const status = await magic.user.isLoggedIn();
        setIsLoggedIn(status);
        setIsSessionValid(status);
        console.log('checking sesh')
    };

    checkSession();

    const intervalId = setInterval(checkSession, 300000); // Check every 5 minutes
    return () => clearInterval(intervalId);
  }, [magic.user]);


  const login = async (email: string) => {
    setAuthLoading(true);
    try {
      const magicAptosWallet = new MagicAptosWallet(magic, {
        connect: async () => {
          await magic.auth.loginWithMagicLink({ email });
          const accountInfo = await magic.aptos.getAccountInfo();
          return accountInfo;
        },
      });

      const accountInfo = await magicAptosWallet.connect();
      getBalance(accountInfo.address);
      setAccountInfo(accountInfo);
      setAptosWallet(magicAptosWallet);
      setIsLoggedIn(true);
      setIsSessionValid(true);
    } catch (error) {
      console.error("Login Error: ", error);
    } finally {
      setAuthLoading(false);
    }
  };

  const requestMedia = async (mediaAddress: string, itemAddress: string): Promise<string | null> => {
    try{
      if(isSessionValid && accountInfo) {
        const streamUrl = await getStreamUrl(mediaAddress, itemAddress, accountInfo.address);
        return streamUrl;
      } else {
        console.error("User is not authenticated");
        return null
      }
    } catch (error: any) {
      console.error("Error requesting stream:", error);
      return null;
    }
  };

  const getBalance = async (address: string) => {
    const client = new AptosClient(TEST_NODE);
    const coinClient = new CoinClient(client);

    try {
        const balance = await coinClient.checkBalance(address);
        setBalance(balance);
    } catch (error) {
        // If there's an error (e.g., resource not found), set balance to 0
        console.error("Error fetching balance:", error); // Log the error for debugging
        setBalance(BigInt(0));
    }
};

  const logout = async () => {
    try {
      await magic.user.logout();
      setIsLoggedIn(false);
      setAptosWallet(null);
      setAccountInfo(null);
      setNetworkInfo(null);
      setBalance(BigInt(0));
      setIsSessionValid(false);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, authLoading, requestMedia, balance, login, logout,  accountInfo, networkInfo, aptosWallet, isSessionValid }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
