import React from "react";
import './confirmation-dialog.css';

type ConfirmationDialogProps = {
  message: string;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ message, isOpen, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="confirmation-dialog-overlay">
      <div className="confirmation-dialog">
        <p style={{marginBlockEnd: '2rem', textAlign: 'center'}}>{message}</p>
        <div style={{ display: "flex", gap: '1.5rem'}}>
          <button onClick={onCancel}>Cancel</button>
          <button onClick={onConfirm}>Yes</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
