export function copyToClipboard(text: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const navigatorAny = window.navigator as any;

    // Modern way using Clipboard API
    if (navigatorAny.clipboard && navigatorAny.clipboard.writeText) {
      navigatorAny.clipboard.writeText(text).then(() => {
        resolve('Text copied to clipboard');
      }).catch((err: Error) => {
        reject('Failed to copy text: ' + err);
      });
    }
    // Fallback for Internet Explorer
    else if ((window as any).clipboardData && (window as any).clipboardData.setData) {
      const result = (window as any).clipboardData.setData("Text", text) ? 'Text copied to clipboard' : 'Failed to copy text';
      resolve(result);
    }
    // Browser doesn't support clipboard action
    else {
      reject('Your browser does not support clipboard actions');
    }
  });
}

export function padAptosAddress(address: string) {
  // Remove the '0x' prefix
  const strippedAddress = address.startsWith('0x') ? address.slice(2) : address;

  // Check if the length of the stripped address is odd
  if (strippedAddress.length % 2 !== 0) {
    // Pad with one additional zero at the start
    return '0x0' + strippedAddress;
  }

  // Pad with zeros at the start to make the length 64 if it's less than 64
  return '0x' + strippedAddress.padStart(64, '0');
}
