import React, { useState, useEffect } from "react";
import { useAuth } from "../../../context";
import { AudioPlayer, LoadingSpinner } from "../../../components";
import styles from './audio.module.css';
import { PlayIcon, musicGirl, placeholder } from "../../../assets";
import { ToastContainer, toast } from 'react-toastify';
import { Audio } from "../../../types";
import { PauseIcon } from "../../../assets";

interface AudioProps {
  audio: Audio[];
  loading: boolean;
}

const AudioComp: React.FC<AudioProps> = ({ audio, loading }) => {
  const { isSessionValid, requestMedia } = useAuth();
  const [currentSrc, setCurrentSrc] = useState<string>("");
  const [loadingMedia, setLoadingMedia] = useState<string | null>(null);
  const [activeMedia, setActiveMedia] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const originalBackgroundColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = 'white';

    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);

  const handlePlay = async (audio: Audio) => {
    if (!isSessionValid) {
      toast.error("You must be logged in to play this media.");
      return;
    }
    if (!audio.address) {
      toast.error("Error playing this track");
      return;
    }

    if (audio.address !== activeMedia) {
      setLoadingMedia(audio.address);
      setActiveMedia(null);

      try {
        let newSrc;
        if(audio.itemAddress){
          console.log(`audio ${audio.address}`);
          console.log(`item ${audio.itemAddress}`);
          newSrc = await requestMedia(audio.address, audio.itemAddress);
        } else {
          newSrc = await requestMedia(audio.address, audio.address);
        }

        if (newSrc) {
          setCurrentSrc(newSrc);
          setIsPlaying(true);
          setActiveMedia(audio.address);
        } else {
          console.log('Could not load');
          toast.error("Error loading media source. Please try again later.");
        }
      } catch (error: any) {
        console.error("Error fetching media:", error);
        toast.error("Error loading media. Please try again later.");
      } finally {
        setLoadingMedia(null);
      }
    } else if (isPlaying) {
      handlePause();
    } else {
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const playFirstAudio = () => {
    if (activeMedia) {
      setIsPlaying(true);
      return
    } else if (audio.length > 0) {
      handlePlay(audio[0]);
    } else {
      toast.error("No audio available");
    }
  };

  const showToastError = (message: string) => {
      toast.error(message);
  };

  const renderLoadingRows = (count: number) => {
    return Array.from({ length: count }, (_, index) => (
      <tr key={`loading-${index}`}>
        <td className={`${styles.loadingGradient} loading-gradient`}></td>
        <td className={`${styles.loadingGradient} loading-gradient`}></td>
        <td className={`${styles.loadingGradient} loading-gradient`}></td>
        <td className={`${styles.loadingGradient} loading-gradient`}></td>
        <td className={`${styles.loadingGradient} loading-gradient`}></td>
      </tr>
    ));
  };

  return (
    <div style={{maxWidth: '1120px', marginInline: 'auto'}}>
      <section className={styles.audioHeader}>
        <img src={musicGirl} alt="girl-listening-to-music" className={styles.headingImage}/>
        <div className={styles.audioInfo}>
          <h1>My Audio</h1>
          <p>{audio.length} songs</p>
        </div>
      </section>
      <section className={styles.audioNav}>
        <div
          onClick={isPlaying ? handlePause : playFirstAudio}
          className={styles.playAllButton}
        >
          {isPlaying ? <PauseIcon/> : <PlayIcon/>}
        </div>
      </section>
      <section className={styles.audioTableContainer}>
        <table className={styles.audioTable}>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Duration</th>
              <th>Published</th>
            </tr>
          </thead>
          <tbody>
            {loading
              ? renderLoadingRows(2)
              : audio.map((audio, index) => (
              <tr className={`${styles.audioRow}`}
                  key={audio.address} onClick={() => handlePlay(audio)}>
                <td className={styles.audioNumber}>
                  <div className={styles.numberSpinnerContainer}>
                    {loadingMedia === audio.address ? (
                      <LoadingSpinner />
                    ) : activeMedia === audio.address ? (
                      isPlaying ? (
                        <PauseIcon onClick={handlePause} />
                      ) : (
                        <>
                          <span className={`${styles.number} ${activeMedia === audio.address ? styles.active : ''}`} onClick={() => handlePlay(audio)}>{index + 1}</span>
                          <span className={styles.playIcon}>
                            <span className={styles.playIconHover}>
                              <PlayIcon />
                            </span>
                          </span>
                        </>
                      )
                    ) : (
                      <>
                        <span className={styles.number}>{index + 1}</span>
                        <span className={styles.playIcon}>
                          {activeMedia !== audio.address && (
                            <span className={styles.playIconHover}>
                              <PlayIcon />
                            </span>
                          )}
                        </span>
                      </>
                    )}
                  </div>
                </td>
                <td className={styles.audioDetail}>
                  <img src={audio.uri ?? placeholder} alt="Audio Cover"
                   className={styles.audioImage} />
                  <div className={styles.audioInfo}>
                    <div className={`${activeMedia === audio.address ? styles.active : ''}`}>{audio.name}</div>
                    <div className={styles.artist}>{audio.artist}</div>
                  </div>
                </td>
                <td>{audio.duration}</td>
                <td>{audio.published.toString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {audio.length > 0 &&
        <div className={styles.audioPlayerContainer}>
          <AudioPlayer
            src={currentSrc}
            isPlaying={isPlaying}
            onPlayStateChange={setIsPlaying}
            showToastError={showToastError}/>
        </div>}
      <ToastContainer />
    </div>
  );
};

export default AudioComp;
